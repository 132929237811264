import {css} from 'styled-components'
import type {StyledComponentBrand} from 'styled-components/dist/types'

import {tokens} from '../../tokens'

/**
 * Applies standard focus styles, that only get rendered in "keyboard mode",
 * i.e. consistent with the new :focus-visible selector
 */

const focusRingWidth = '3px'
const focusRingColor = tokens.colorShadowFocus
const focusRingColorInvalid = tokens.colorShadowFocusInvalid

const transitionFocusRing = css`
    transition: ${tokens.motionWithinSmallShort};
    transition-property: box-shadow;
`

/* Fallback for Windows High Contrast Mode, as it hides box-shadows */
export const focusRingFallbackStyle = css`
    outline: ${focusRingWidth} solid transparent;
`

/* Export styles for uses in edge cases like focus-within */

export const focusRingRegularStyle = css`
    box-shadow: 0 0 0 ${focusRingWidth} ${focusRingColor};
    ${transitionFocusRing};
`

export const focusRingInsetStyle = css`
    box-shadow: inset 0 0 0 ${focusRingWidth} ${focusRingColor};
    ${transitionFocusRing};
`

export const focusRingOffsetStyle = css`
    box-shadow: 0 0 0 ${focusRingWidth} ${tokens.colorBackgroundStatic},
        0 0 0 ${tokens.spacing6} ${focusRingColor};
    ${transitionFocusRing};
`

const regularFocusRing = css`
    [data-js-focus-visible] &:focus {
        ${focusRingFallbackStyle}
    }

    [data-js-focus-visible] &[data-focus-visible-added]:focus {
        ${focusRingRegularStyle}
    }

    [data-js-focus-visible] &[data-focus-visible-added]:focus:invalid {
        box-shadow: 0 0 0 ${focusRingWidth} ${focusRingColorInvalid};
    }
`

const insetFocusRing = css`
    [data-js-focus-visible] &:focus {
        ${focusRingFallbackStyle}
        outline-style: inset;
    }

    [data-js-focus-visible] &[data-focus-visible-added]:focus {
        ${focusRingInsetStyle}
    }

    [data-js-focus-visible] &[data-focus-visible-added]:focus:invalid {
        box-shadow: inset 0 0 0 ${focusRingWidth} ${focusRingColorInvalid};
    }
`

const offsetFocusRing = css`
    [data-js-focus-visible] &:focus {
        ${focusRingFallbackStyle}
        outline-offset: ${focusRingWidth};
    }

    [data-js-focus-visible] &[data-focus-visible-added]:focus {
        ${focusRingOffsetStyle}
    }

    [data-js-focus-visible] &[data-focus-visible-added]:focus:invalid {
        box-shadow: 0 0 0 ${focusRingWidth} ${tokens.colorBackgroundStatic},
            ${tokens.spacing6} ${focusRingColorInvalid};
    }
`

export const focusRing = (variant: 'regular' | 'inset' | 'offset') => {
    if (variant === 'inset') {
        return insetFocusRing
    } else if (variant === 'offset') {
        return offsetFocusRing
    }
    return regularFocusRing
}

/**
 * Applies focusRing, but is applicable to styles that use
 * hidden elements with styled siblings (like checkboxes or radios)
 */

export const focusRingSibling = (Element: StyledComponentBrand) => css`
    [data-js-focus-visible] ${Element}:focus + & {
        ${focusRingFallbackStyle}
        outline-offset: ${focusRingWidth};
    }

    [data-js-focus-visible] ${Element}[data-focus-visible-added]:focus + & {
        ${focusRingOffsetStyle}
    }

    [data-js-focus-visible] ${Element}[data-focus-visible-added]:focus:invalid + & {
        box-shadow: 0 0 0 ${focusRingWidth} ${tokens.colorBackgroundStatic},
            ${tokens.spacing6} ${focusRingColorInvalid};
    }
`

import React, {ButtonHTMLAttributes} from 'react'

import {Close} from '@pleo-io/telescope-icons'

import * as S from './promotional-banner.styles'

import {Text} from '../text'

export type PromotionalBannerVariants = 'pink' | 'yellow' | 'gray' | 'bordered' | 'purple'

interface PromotionalBannerProps {
    /**
     * Option to change the general look of the banner
     * @default 'pink'
     */
    variant?: PromotionalBannerVariants

    /**
     * The contents of the banner
     */
    children: React.ReactNode
}

interface ContentProps {
    /**
     * The main text content of the banner
     */
    children: JSX.Element | JSX.Element[]
}

interface ContentTextProps {
    /**
     * The text of the banner
     */
    children: JSX.Element | JSX.Element[] | string
}

export interface PromotionalBannerCloseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    onClick: () => void
    ariaLabel: string
}
export interface PromotionalBannerImageProps {
    src: string
    alt: string
}

const Content = (props: ContentProps) => <S.BannerContent>{props.children}</S.BannerContent>

const CloseButton = ({ariaLabel, ...props}: PromotionalBannerCloseButtonProps) => (
    <S.CloseButton aria-label={ariaLabel} {...props}>
        <Close />
    </S.CloseButton>
)

const ContentHeading = (props: ContentTextProps) => (
    <S.ContentHeading as="h2">{props.children}</S.ContentHeading>
)

const ContentText = (props: ContentTextProps) => <Text as="p">{props.children}</Text>

export const PromotionalBanner = (props: PromotionalBannerProps) => {
    return (
        <S.Container>
            <S.PromotionalBannerBase
                $variant={props.variant || 'pink'}
                role="status"
                data-telescope="promotional-banner"
            >
                {props.children}
            </S.PromotionalBannerBase>
        </S.Container>
    )
}

PromotionalBanner.Content = Content
PromotionalBanner.Image = S.Image
PromotionalBanner.CloseButton = CloseButton
PromotionalBanner.ContentHeading = ContentHeading
PromotionalBanner.ContentText = ContentText
PromotionalBanner.CTA = S.BannerCTAButton

PromotionalBanner.displayName = 'PromotionalBanner'

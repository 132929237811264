import React from 'react'
import styled from 'styled-components'

import {Help} from '@pleo-io/telescope-icons'

import {tokens} from '../../tokens'
import {NakedButton} from '../button'
import {Popover, PopoverContentProps} from '../popover'
import {Text} from '../text'

export type HelpPopoverProps = Pick<PopoverContentProps, 'side'> & {
    /**
     * The content of the help popover
     */
    children: React.ReactNode
    /**
     * Id(s) of the element(s) that labels the help icon (trigger) for screen readers
     */
    'aria-labelledby'?: string
    /**
     * Describes the help icon (trigger) for screen readers
     */
    'aria-label'?: string
    /**
     * Optional class name for extending styles
     */
    className?: string
    /**
     * The size of the help icon (trigger)
     */
    size?: 'regular' | 'large'
}

const ContentWrapper = styled(Text)`
    max-width: 280px;
    padding: ${tokens.spacing16};
`

export const Trigger = styled(NakedButton)`
    color: ${tokens.colorContentInteractive};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }
`

export const HelpPopover = ({
    children,
    className,
    size = 'regular',
    // Setting default to top as it's most commonly used next to an
    // input label and would otherwise overlay the input field
    side = 'top',
    'aria-labelledby': ariaLabelledBy,
    'aria-label': ariaLabel
}: HelpPopoverProps) => {
    return (
        <Popover>
            <Popover.Trigger>
                <Trigger aria-label={ariaLabel} aria-labelledby={ariaLabelledBy}>
                    <Help size={size === 'large' ? 24 : 16} />
                </Trigger>
            </Popover.Trigger>
            <Popover.Content side={side}>
                <Popover.Arrow />
                <ContentWrapper className={className}>{children}</ContentWrapper>
            </Popover.Content>
        </Popover>
    )
}

import React from 'react'
import styled, {css} from 'styled-components'

import {fontDefault} from '@pleo-io/telescope-global-style'

import type {ButtonProps} from './button'

import {tokens} from '../../tokens'
import {focusRing} from '../focus-ring'
import {linkBaseStyles, linkColor} from '../link'
import {Spinner} from '../spinner'
import {Text} from '../text'

const disabledButtonStyles = css`
    color: ${tokens.colorContentInteractiveDisabled};
    background-color: ${tokens.colorBackgroundInteractiveDisabled};
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
        color: ${tokens.colorContentInteractiveDisabled};
    }
`

const primaryButtonStyles = css`
    color: ${tokens.colorContentInteractiveInverse};
    background-color: ${tokens.colorBackgroundInteractiveInverse};

    &:hover,
    &:active {
        color: ${tokens.colorContentInteractiveInverseHover};
        background-color: ${tokens.colorBackgroundInteractiveInverseHover};
    }
`

const secondaryButtonStyles = css`
    color: ${tokens.colorContentInteractive};
    background-color: ${tokens.colorBackgroundInteractive};
    border-color: ${tokens.colorBorderInteractiveQuiet};

    &:hover,
    &:active {
        color: ${tokens.colorContentInteractiveHover};
        border-color: ${tokens.colorBorderInteractiveHover};
    }
`

const primaryDestructiveButtonStyles = css`
    color: ${tokens.colorContentInteractiveInverse};
    background-color: ${tokens.colorBackgroundNegative};

    &:hover,
    &:active {
        color: ${tokens.colorContentInteractiveInverseHover};
        background-color: ${tokens.colorBackgroundNegativeHover};
    }
`

const secondaryDestructiveButtonStyles = css`
    background-color: ${tokens.colorBackgroundInteractive};
    border-color: ${tokens.colorBorderNegative};
    color: ${tokens.colorContentNegative};

    &:hover,
    &:active {
        border-color: ${tokens.colorBorderNegativeHover};
        color: ${tokens.colorContentNegativeHover};
    }
`

const tertiaryDestructiveButtonStyles = css`
    color: ${tokens.colorContentNegative};

    &:hover,
    &:active {
        color: ${tokens.colorContentNegativeHover};
    }
`

const fullWidthStyles = css`
    flex: 1;
    width: 100%;
`

const getVariantStyles = (props: ButtonProps) => {
    if (props.disabled) {
        return disabledButtonStyles
    }

    if (props.destructive) {
        switch (props.variant) {
            case 'primary':
                return primaryDestructiveButtonStyles
            case 'secondary':
                return secondaryDestructiveButtonStyles
            case 'tertiary':
                return tertiaryDestructiveButtonStyles
        }
    }

    switch (props.variant) {
        case 'primary':
            return primaryButtonStyles
        case 'secondary':
            return secondaryButtonStyles
    }

    return primaryButtonStyles
}

const disabledButtonLinkStyles = css`
    color: ${tokens.colorContentInteractiveDisabled};
    cursor: not-allowed;

    &:hover,
    &:active {
        color: ${tokens.colorContentInteractiveDisabled};
    }
`

const getColorStyles = (props: ButtonProps) => {
    if (props.disabled) {
        return disabledButtonLinkStyles
    } else if (props.variant === 'link') {
        return linkColor
    } else if (props.variant === 'tertiary') {
        return props.destructive ? tertiaryDestructiveButtonStyles : linkColor
    }

    return linkColor
}

const TRANSIENT_PROPS: (keyof ButtonProps)[] = [
    'variant',
    'destructive',
    'fullWidth',
    'tooltipProps',
    'inherit',
    'loading',
    'loadingText',
    'IconLeft',
    'IconRight'
]
const shouldForwardProp = (propName: string) =>
    !TRANSIENT_PROPS.includes(propName as keyof ButtonProps)

export const BaseButton = styled.div.withConfig({shouldForwardProp})<ButtonProps>`
    ${focusRing('offset')}
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: ${tokens.spacing8} ${tokens.spacing24};
    font-weight: ${tokens.fontWeightRegular};
    font-size: ${tokens.fontMedium};
    font-family: ${fontDefault};
    line-height: ${tokens.lineHeight1};
    white-space: nowrap;
    border: ${tokens.sizeBorderThick} solid transparent;
    text-decoration: none;
    height: ${tokens.heightInputAndButton};
    border-radius: ${tokens.arc99999};
    cursor: pointer;
    transition: ${tokens.motionWithinSmallShort};
    transition-property: background, border, color;
    appearance: none;
    ${getVariantStyles};

    [data-telescope='icon'] {
        width: 16px;
        height: 16px;
    }

    ${({fullWidth}) => fullWidth && fullWidthStyles};

    &:disabled {
        ${disabledButtonStyles};
    }
`

export const ButtonLink = styled.div.withConfig({shouldForwardProp})<ButtonProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-decoration: none;
    ${linkBaseStyles};
    ${getColorStyles};
    ${({fullWidth}) => fullWidth && fullWidthStyles};
    background: none;
    border: none;

    &:disabled {
        ${disabledButtonLinkStyles};
    }
`

export const Hidden = styled.span`
    display: flex;
    visibility: hidden;
`

export const LoadingWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
`

const StyledLoader = styled.span`
    display: inline-flex;
`

export const SpinningLoader = () => (
    <StyledLoader>
        <Spinner />
    </StyledLoader>
)

export const StyledLoadingText = styled(Text).attrs({color: 'inherit'})`
    margin-left: ${tokens.spacing8};
`

export const NakedButton = styled.button.attrs((props) => ({
    type: props.type ?? 'button'
}))`
    ${focusRing('regular')}
    margin: 0;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    background: none;
    border: none;
    cursor: pointer;
`

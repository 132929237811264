import React, {HTMLAttributes} from 'react'

import {Info, Warning, Rocket, CircleCheck, Close} from '@pleo-io/telescope-icons'

import {BannerBase, BannerContent} from './banner.styles'

import {theme} from '../../utils'
import {IconButton, IconButtonProps} from '../icon-button'
import {Inline} from '../inline'
import {Text, TextProps} from '../text'

export type BannerVariant = 'negative' | 'warning' | 'discover' | 'neutral' | 'info' | 'positive'

export interface BannerProps {
    /**
     * Option to change the look of the Banner
     * @default 'neutral'
     */
    variant?: BannerVariant
    /**
     * Adds stripes to the top border of the banner to make it stand out more.
     * @default false
     */
    loud?: boolean
    /**
     * Icon to override the default Icon
     */
    Icon?: React.ComponentType
    /**
     * The contents of the Banner
     */
    children: React.ReactNode
}

const variantIcons: Record<BannerVariant, React.ComponentType> = {
    neutral: Info,
    info: Info,
    warning: Warning,
    negative: Warning,
    positive: CircleCheck,
    discover: Rocket
}

export const Banner = ({
    variant = 'neutral',
    loud,
    children,
    className,
    Icon,
    ...props
}: BannerProps & HTMLAttributes<HTMLDivElement>) => {
    const BannerIcon = Icon ?? variantIcons[variant]
    return (
        <BannerBase
            data-telescope="banner"
            $variant={variant}
            $loud={loud}
            className={`${className || ''} ${theme[variant]}`}
            {...props}
        >
            <Inline py={8} px={24} space={8} alignItems="center">
                <BannerIcon />
                {children}
            </Inline>
        </BannerBase>
    )
}

const BannerText = ({
    ...props
}: Omit<TextProps, 'weight' | 'align'> & {children?: React.ReactNode}) => (
    <Text weight="medium" align="left" {...props} />
)

const CloseButton = ({
    ...props
}: Omit<IconButtonProps, 'variant' | 'Icon' | 'size' | 'tooltipProps'> & {
    'aria-label': string
}) => (
    <IconButton
        css={{flexShrink: 0}}
        variant="secondary"
        size="extraSmall"
        Icon={Close}
        tooltipProps={{
            dangerouslyOmitTooltip: true,
            'aria-label': props['aria-label']
        }}
        {...props}
    />
)

Banner.Text = BannerText
Banner.Content = BannerContent
Banner.CloseButton = CloseButton

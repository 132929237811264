import React from 'react'
import ReactSelect, {
    GroupBase,
    Props,
    components as reactSelectComponents,
    ClearIndicatorProps,
    MultiValueRemoveProps,
    DropdownIndicatorProps,
    OptionProps as ReactSelectOptionProps,
    SingleValueProps as ReactSelectSingleValueProps,
    PlaceholderProps,
    MultiValueGenericProps,
    ControlProps
} from 'react-select'
import AsyncSelect, {AsyncProps} from 'react-select/async'
import AsyncCreatableSelect, {AsyncCreatableProps} from 'react-select/async-creatable'
import CreatableSelect, {CreatableProps} from 'react-select/creatable'

import {Close, Plus} from '@pleo-io/telescope-icons'

import {Prefix, SelectOption as SelectOptionComponent} from './select-option'
import * as s from './select.styles'

import {tokens} from '../../tokens'
import {Avatar} from '../avatar'
import {Box} from '../box'
import {useFormControlId, Label} from '../form-control'
import {Spinner} from '../spinner'
import {Text} from '../text'

// Recommended way to add custom props. See this:
// https://react-select.com/typescript#custom-select-props
// This will make the custom prop available both when using
// the Select component as well as when accessing selectProps
// when customising components.
declare module 'react-select/dist/declarations/src/Select' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
        /**
         * The label to be shown above the select input
         */
        label?: string
        /**
         * Makes the select input disabled
         */
        disabled?: boolean
        /**
         * Makes the select input appear invalid
         */
        isInvalid?: boolean
        /**
         * If true, the menu will be attached to document.body
         */
        portalled?: boolean
        /**
         * Prefer `FormControl` instead
         */
        renderError?: () => React.ReactNode
        /**
         * An icon to show before the selected value
         */
        PrefixIcon?: React.ComponentType
        /**
         * An id to be used for testing purposes
         */
        testId?: string
        /**
         * Define a maximum width of the select input
         */
        maxWidth?: string
        /**
         * If true, the menu can expand the width of the select input
         */
        allowMenuOverflow?: boolean
        /**
         * Enable creatable that allows users to add new options dynamically
         * @experimental
         */
        isCreatable?: boolean
        /**
         * When combined with `isCreatable`, this will be shown as the pre-input text on the creatable option
         * @experimental
         */
        createLabel?: (inputValue: string) => string
        /**
         * When combined with `isCreatable`, this will be shown as the icon on the creatable option
         * @experimental
         * @default Plus
         */
        CreateIcon?: React.FC<{size?: any; color?: any}>
        /**
         * When combined with `isCreatable`, this will be called with the input value when a new option is created, and onChange will not be called. Use this when you need more control over what happens when new options are created.
         * @experimental
         */
        onCreateOption?: (inputValue: string) => void
        /**
         * If true, the selected value will be shown on hover
         */
        showValueOnHover?: boolean
        /**
         * Enable async loading of options
         * @experimental
         */
        isAsync?: boolean
        /**
         * When combined with `isAsync`, this will be shown while loading options
         * @experimental
         */
        loadingLabel?: string
    }
}

type MergedProps<
    Option extends SelectTypes.Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
> =
    | ({isCreatable: true; isAsync: true} & AsyncCreatableProps<Option, IsMulti, Group>)
    | ({isCreatable: true} & CreatableProps<Option, IsMulti, Group>)
    | ({isAsync: true} & AsyncProps<Option, IsMulti, Group>)
    | Props<Option, IsMulti, Group>

export namespace SelectTypes {
    export type Avatar = {name: string; src?: string; size?: 24 | 40}
    export type Option = {
        readonly value: string
        readonly label: string
        readonly icon?: React.ReactElement | string
        readonly description?: string
        readonly disabled?: boolean
        readonly avatar?: Avatar
    }

    export type GroupedOption = {
        readonly label: string
        readonly options: readonly Option[]
    }

    export type SingleValueProps<
        Option,
        IsMulti extends boolean = false,
        Group extends GroupBase<Option> = GroupBase<Option>
    > = ReactSelectSingleValueProps<Option, IsMulti, Group>

    export type OptionProps<
        Option,
        IsMulti extends boolean = false,
        Group extends GroupBase<Option> = GroupBase<Option>
    > = ReactSelectOptionProps<Option, IsMulti, Group>

    export type SelectProps<Option> = Props<Option>
}

const placeholderAndSingleValueStyles = {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: tokens.spacing8,
    textAlign: 'left',
    lineHeight: 1.5
} as const

const widerMenuStyles = {
    width: 'max-content',
    minWidth: '100%',
    maxWidth: '60ch'
}

const getSelectComponent = ({isCreatable, isAsync}: {isCreatable?: boolean; isAsync?: boolean}) => {
    if (isCreatable && isAsync) {
        return AsyncCreatableSelect
    } else if (isCreatable) {
        return CreatableSelect
    } else if (isAsync) {
        return AsyncSelect
    }
    return ReactSelect
}

export function Select<
    Option extends SelectTypes.Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>({
    label,
    components,
    portalled,
    className,
    isMulti,
    isOptionDisabled,
    closeMenuOnSelect = !isMulti,
    testId,
    maxWidth,
    menuPlacement,
    allowMenuOverflow,
    showValueOnHover,
    isAsync,
    isCreatable,
    createLabel,
    CreateIcon,
    onCreateOption,
    loadingLabel,
    ...props
}: MergedProps<Option, IsMulti, Group>) {
    const id = useFormControlId(props.name)

    const isBrowser = typeof window !== 'undefined' && portalled
    const SelectComponent = getSelectComponent({isCreatable, isAsync})

    return (
        <s.Wrapper
            className={className}
            data-testid={testId ?? 'select-wrapper'}
            $maxWidth={maxWidth}
            data-telescope="select"
        >
            {label && (
                <Label
                    // Will be fixed in https://linear.app/pleo/issue/WEB-789/replace-react-docgen-typescript-with-react-docgen
                    // @ts-expect-error htmlFor prop missing
                    htmlFor={id}
                    as="label"
                >
                    {label}
                </Label>
            )}
            <SelectComponent
                isDisabled={props.disabled}
                options={props.options}
                name={props.name}
                inputId={id}
                hideSelectedOptions={false}
                menuPlacement={menuPlacement || 'auto'}
                menuIsOpen={props.menuIsOpen}
                menuPortalTarget={portalled && isBrowser ? document.body : undefined}
                isMulti={isMulti}
                closeMenuOnSelect={closeMenuOnSelect}
                isOptionDisabled={
                    isOptionDisabled ? isOptionDisabled : (option) => option?.disabled ?? false
                }
                onCreateOption={onCreateOption}
                components={{
                    Control: showValueOnHover ? DefaultControl : reactSelectComponents.Control,
                    DropdownIndicator,
                    Option: (props) => {
                        const {data, ...rest} = props
                        return <SelectOptionComponent {...rest} {...data} />
                    },
                    SingleValue: DefaultSingleValue,
                    ClearIndicator,
                    MultiValueRemove,
                    Placeholder,
                    MultiValueLabel: DefaultMultiValueLabel,
                    NoOptionsMessage: ({selectProps: {noOptionsMessage, inputValue}}) => (
                        <Box px={12} py={8}>
                            <Text align="center" color="shade600">
                                {noOptionsMessage({inputValue})}
                            </Text>
                        </Box>
                    ),
                    LoadingMessage: () => (
                        <Box px={12} py={8}>
                            <Text align="center" color="shade600">
                                {loadingLabel}
                            </Text>
                        </Box>
                    ),
                    LoadingIndicator: () => <Spinner />,
                    ...components
                }}
                formatCreateLabel={(inputValue) => (
                    <s.CreateableWrapper>
                        <Prefix>
                            {CreateIcon ? (
                                <CreateIcon size={16} color={tokens.colorContentInteractiveLink} />
                            ) : (
                                <Plus color={tokens.colorContentInteractiveLink} size={16} />
                            )}
                        </Prefix>
                        <Text as="span" color="colorContentInteractiveLink">
                            {!!createLabel ? createLabel(inputValue) : `"${inputValue}"`}
                        </Text>
                    </s.CreateableWrapper>
                )}
                styles={{
                    container: (base) => ({
                        ...base,
                        fontSize: tokens.fontMedium,
                        width: '100%',
                        minWidth: 0
                    }),
                    option: () => ({}),
                    groupHeading: (base) => ({
                        ...base,
                        fontWeight: tokens.fontWeightSemibold,
                        fontSize: tokens.fontSmall,
                        marginBottom: tokens.spacing4,
                        color: tokens.colorContentInteractive,
                        textTransform: 'capitalize'
                    }),
                    control: (base) => ({
                        ...base,
                        boxShadow: 'none',
                        minHeight: tokens.heightInputAndButton,
                        border: props.disabled
                            ? `${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveDisabled}`
                            : props.isInvalid
                            ? `${tokens.sizeBorderDefault} solid ${tokens.colorBorderNegative}`
                            : `${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveQuiet}`,
                        borderRadius: tokens.spacing8,
                        backgroundColor: props.disabled
                            ? tokens.colorBackgroundInteractiveDisabled
                            : tokens.colorBackgroundInteractive,

                        '&:hover': {
                            border: props.isInvalid
                                ? `${tokens.sizeBorderDefault} solid ${tokens.colorBorderNegativeHover}`
                                : `${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveHover}`,
                            cursor: props.disabled ? 'not-allowed' : 'pointer'
                        },

                        '&:focus-within': {
                            border: props.isInvalid
                                ? `${tokens.sizeBorderDefault} solid ${tokens.colorShadowFocusInvalid}`
                                : `${tokens.sizeBorderDefault} solid ${tokens.colorShadowFocus}`,

                            boxShadow: props.isInvalid
                                ? `0 0 0 2px ${tokens.colorShadowFocusInvalid}`
                                : `0 0 0 2px ${tokens.colorShadowFocus}`
                        }
                    }),
                    group: (base) => ({
                        ...base,
                        marginTop: tokens.spacing16,
                        padding: 0
                    }),
                    placeholder: (base, state) => ({
                        ...base,
                        ...placeholderAndSingleValueStyles,
                        whiteSpace: 'nowrap',
                        color: state.isDisabled
                            ? tokens.colorContentInteractiveDisabled
                            : tokens.colorContentInteractivePlaceholder
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        backgroundColor: 'transparent',
                        paddingLeft: tokens.spacing10
                    }),
                    input: (base) => ({...base, backgroundColor: 'transparent'}),
                    singleValue: (base, state) => ({
                        ...base,
                        ...placeholderAndSingleValueStyles,
                        color: state.isDisabled
                            ? tokens.colorContentInteractiveDisabled
                            : tokens.colorContentInteractive
                    }),
                    multiValue: (base, state) => ({
                        ...base,
                        backgroundColor: tokens.colorBackgroundInteractive,
                        borderRadius: tokens.arc4,
                        '[role="button"]': {
                            border: state.isFocused
                                ? `${tokens.sizeBorderThick} solid ${tokens.colorShadowFocus}`
                                : 'none'
                        }
                    }),
                    multiValueLabel: (base, state) => ({
                        ...base,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: tokens.fontMedium,
                        // Edge case on disabled background. In this case the select have disabled background color, so to make labels visible, we give them interactive background
                        backgroundColor: state.isDisabled
                            ? tokens.colorBackgroundInteractive
                            : tokens.colorBackgroundPresentationalPink,
                        color: state.isDisabled
                            ? tokens.colorContentInteractiveDisabled
                            : tokens.colorContentInteractive,
                        lineHeight: 1.5,
                        height: 28,
                        paddingLeft: tokens.spacing8,
                        paddingRight: state.isDisabled ? tokens.spacing8 : 0,
                        border: state.isDisabled
                            ? `${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveDisabled}`
                            : 'none',
                        borderRadius: state.isDisabled
                            ? tokens.arc4
                            : `${tokens.arc4} 0 0 ${tokens.arc4}`
                    }),
                    multiValueRemove: () => ({
                        background: tokens.colorBackgroundInteractive
                    }),
                    menuList: (base) => ({...base, padding: 0}),
                    menu: (base) => ({
                        ...base,
                        padding: `${tokens.spacing8} 0`,
                        marginTop: tokens.spacing8,
                        background: tokens.colorBackgroundStatic,
                        overflow: 'hidden',
                        boxShadow: tokens.shadowElevateQuiet,
                        border: `${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic}`,
                        borderRadius: tokens.arc8,
                        zIndex: tokens.zIndexDropdown,
                        ...(allowMenuOverflow && widerMenuStyles)
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        padding: 0,
                        color: tokens.colorContentInteractiveQuiet,

                        '&:hover': {
                            color: tokens.colorContentInteractiveHover
                        }
                    }),
                    dropdownIndicator: (base) => ({...base, paddingRight: tokens.spacing12}),
                    indicatorSeparator: (base) => ({...base, display: 'none'}),
                    indicatorsContainer: (base) => ({
                        ...base
                    })
                }}
                {...props}
            />
            {props.renderError && props.renderError()}
        </s.Wrapper>
    )
}

function DefaultSingleValue<
    Option extends {icon?: React.ReactNode; avatar?: SelectTypes.Avatar},
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(props: SelectTypes.SingleValueProps<Option, IsMulti, Group>) {
    let icon
    if (props.data.avatar) {
        icon = <Avatar {...props.data.avatar} size={24} />
    } else if (props.data.icon) {
        icon = props.data.icon
    } else if (props.selectProps.PrefixIcon) {
        icon = <props.selectProps.PrefixIcon />
    }

    return (
        <reactSelectComponents.SingleValue {...props}>
            {icon ? <s.IconWrapper>{icon}</s.IconWrapper> : null}
            <s.ValueWrapper>{props.children}</s.ValueWrapper>
        </reactSelectComponents.SingleValue>
    )
}

function DropdownIndicator<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(props: DropdownIndicatorProps<Option, IsMulti, Group>) {
    return (
        <reactSelectComponents.DropdownIndicator {...props}>
            <s.StyledChevron
                size={16}
                $isDisabled={props.selectProps.isDisabled}
                $menuIsOpen={props.selectProps.menuIsOpen}
            />
        </reactSelectComponents.DropdownIndicator>
    )
}

function ClearIndicator<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(props: ClearIndicatorProps<Option, IsMulti, Group>) {
    return (
        <reactSelectComponents.ClearIndicator {...props}>
            <Close size={16} />
        </reactSelectComponents.ClearIndicator>
    )
}

function Placeholder<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(props: PlaceholderProps<Option, IsMulti, Group>) {
    const PrefixIcon = props.selectProps.PrefixIcon
    return (
        <reactSelectComponents.Placeholder {...props}>
            {PrefixIcon && (
                <s.IconWrapper>
                    <PrefixIcon />
                </s.IconWrapper>
            )}
            <s.ValueWrapper>{props.children}</s.ValueWrapper>
        </reactSelectComponents.Placeholder>
    )
}

function DefaultMultiValueLabel<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(props: MultiValueGenericProps<Option, IsMulti, Group>) {
    return (
        <reactSelectComponents.MultiValueLabel {...props}>
            <s.ValueWrapper>{props.children}</s.ValueWrapper>
        </reactSelectComponents.MultiValueLabel>
    )
}

function MultiValueRemove<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(props: MultiValueRemoveProps<Option, IsMulti, Group>) {
    return (
        <s.MultiValueRemove $isDisabled={props.selectProps.isDisabled}>
            <reactSelectComponents.MultiValueRemove {...props}>
                <Close size={16} color={tokens.colorContentInteractive} />
            </reactSelectComponents.MultiValueRemove>
        </s.MultiValueRemove>
    )
}

const DefaultControl = <
    Option extends SelectTypes.Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
>({
    children,
    ...props
}: ControlProps<Option, IsMulti, Group>) => {
    const values = props.getValue()
    const title = values.map((value: Option) => value.label).join(', ')
    const innerProps = {...props.innerProps, title}

    return (
        <reactSelectComponents.Control {...props} innerProps={innerProps}>
            {children}
        </reactSelectComponents.Control>
    )
}

export const SelectOption = reactSelectComponents.Option
export const SelectSingleValue = reactSelectComponents.SingleValue
export const SelectMultiValue = reactSelectComponents.MultiValue

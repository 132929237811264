import type {HTMLAttributes, OlHTMLAttributes} from 'react'
import styled from 'styled-components'

import type {Props as IconProps} from '@pleo-io/telescope-icons'

import {tokens} from '../../tokens'

interface ListIconProps extends IconProps {
    /**
     * Color fill for the icon.
     * @default `tokens.colorContentStatic`
     */
    color?: string
    /**
     * Size is based on the font size but can be overridden to a specific size.
     * @default 14
     */
    size?: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 28 | 32
}

export const ListIcon = styled.div.attrs<ListIconProps>(({size, color}) => ({
    size: size ?? 14,
    mr: 12,
    color: color ?? tokens.colorContentStatic
}))<ListIconProps>`
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    margin-top: -0.2em;
    vertical-align: middle;
`

ListIcon.displayName = 'ListIcon'

const listStyles = {
    number: 'decimal',
    bullet: 'disc',
    none: 'none'
} as const

interface ListeBaseProps {
    /**
     * Style of the list. Default is 'none'.

     * @default none
     */
    listStyle?: keyof typeof listStyles

    as?: 'ul' | 'ol'
}

interface UListProps extends ListeBaseProps, HTMLAttributes<HTMLUListElement> {
    /**
     * Set as ordered list (ol) or unordered list (ul).
     * @type 'ol' | 'ul'
     * @default ul
     */
    as?: 'ul'
}

interface OListProps extends ListeBaseProps, OlHTMLAttributes<HTMLOListElement> {
    as?: 'ol'
}

type ListProps = UListProps | OListProps

export const List = styled.ul
    .withConfig({shouldForwardProp: (prop: string) => prop !== 'listStyle'})
    .attrs({
        /**
         * Fix the Safari accessibility issue with list-style-type: none
         * @see https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
         */
        role: 'list',
        'data-telescope': 'list'
    })<ListProps>`
    margin: 0;
    padding: 0;
    list-style-position: inside;
    list-style-type: ${({listStyle = 'none'}) => listStyles[listStyle]};
`

List.displayName = 'List'

export const ListItem = styled.li`
    margin-bottom: ${tokens.spacing12};
    color: ${tokens.colorContentStatic};
    font-size: ${tokens.fontMedium};
    line-height: ${tokens.lineHeight1};

    &:last-child {
        margin-bottom: ${tokens.spacing20};
    }
`

ListItem.displayName = 'ListItem'

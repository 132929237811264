import {tokens} from '../../tokens'

const colors = [
    tokens.colorBackgroundPresentationalYellow,
    tokens.colorBackgroundPresentationalPink,
    tokens.colorBackgroundPresentationalGreen,
    tokens.colorBackgroundPresentationalPurple,
    tokens.colorBackgroundPresentationalPink,
    tokens.colorBackgroundPresentationalYellow
]

function stringToInteger(string: string) {
    let total = 0
    for (let i = 0; i !== string.length; i++) {
        if (total >= Number.MAX_SAFE_INTEGER) {
            break
        }
        total += string.charCodeAt(i)
    }
    return total
}

export const randomColor = (seed: string) => {
    const index = stringToInteger(seed) % colors.length
    return colors[index]
}

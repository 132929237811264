import {createContext, useContext} from 'react'

const defaultLocalisationContextValue = {
    Callout: {
        CloseButton: ''
    },
    Modal: {
        CloseButton: ''
    },
    ModalSplit: {
        BackButton: '',
        StepButton: ''
    },
    FormControl: {
        Error: ''
    }
}

export type LocalisationContextType = typeof defaultLocalisationContextValue

const LocalisationContext = createContext<LocalisationContextType>(defaultLocalisationContextValue)

export const useLocalisation = () => useContext(LocalisationContext)

export const LocalisationProvider = LocalisationContext.Provider

import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import React from 'react'

import {Check} from '@pleo-io/telescope-icons'

import {
    StyledActionMenuContent,
    StyledActionMenuTrigger,
    StyledActionMenuItem,
    StyledActionMenuRadioGroup,
    StyledActionMenuRadioItem,
    IconWrapper,
    DescriptionText,
    StyledActionMenuSeparator
} from './action-menu.styles'

import {Inline} from '../inline'
import {Stack} from '../stack'
interface ActionMenuItemProps extends RadixDropdownMenu.DropdownMenuItemProps {
    /** Add an icon to the left of the label */
    Icon?: React.FC<{size?: any; color?: any}>
    /** Change the appearance of a destructive item */
    isDestructive?: boolean
    /** Add a description to an item */
    description?: string
}

interface ActionMenuRadioItemProps extends RadixDropdownMenu.DropdownMenuRadioItemProps {
    /** Add an icon to the left of the label */
    Icon?: React.FC<{size?: any; color?: any}>
}

interface ActionMenuContentProps extends RadixDropdownMenu.DropdownMenuContentProps {
    /** Render the content in a portal */
    portalled?: boolean
}

const ActionMenuTrigger = ({children, ...props}: RadixDropdownMenu.DropdownMenuTriggerProps) => (
    <StyledActionMenuTrigger {...props} asChild>
        {children}
    </StyledActionMenuTrigger>
)

const ActionMenuSeparator = (props: RadixDropdownMenu.DropdownMenuSeparatorProps) => (
    <StyledActionMenuSeparator {...props} />
)

const ActionMenuContent = ({children, portalled = true, ...props}: ActionMenuContentProps) => {
    const Wrapper = portalled ? RadixDropdownMenu.Portal : React.Fragment

    return (
        <Wrapper>
            <StyledActionMenuContent sideOffset={8} collisionPadding={8} {...props}>
                {children}
            </StyledActionMenuContent>
        </Wrapper>
    )
}

const ActionMenuItem = ({Icon, description, isDestructive, ...props}: ActionMenuItemProps) => {
    const children = (
        <>
            {Icon && (
                <IconWrapper>
                    <Icon size={16} />
                </IconWrapper>
            )}
            <Stack>
                {props.children}
                {description && (
                    <DescriptionText $isDestructive={isDestructive}>{description}</DescriptionText>
                )}
            </Stack>
        </>
    )
    return (
        <StyledActionMenuItem {...props} $isDestructive={isDestructive}>
            {children}
        </StyledActionMenuItem>
    )
}

const ActionMenuRadioGroup = ({
    children,
    ...props
}: RadixDropdownMenu.DropdownMenuRadioGroupProps) => {
    return <StyledActionMenuRadioGroup {...props}>{children}</StyledActionMenuRadioGroup>
}

const ActionMenuItemIndicator = (props: RadixDropdownMenu.DropdownMenuItemIndicatorProps) => (
    <RadixDropdownMenu.ItemIndicator {...props}>
        <IconWrapper>
            <Check size={16} />
        </IconWrapper>
    </RadixDropdownMenu.ItemIndicator>
)

const ActionMenuRadioItem = ({Icon, ...props}: ActionMenuRadioItemProps) => {
    const children = (
        <Inline space={8}>
            {Icon && (
                <IconWrapper>
                    <Icon size={16} />
                </IconWrapper>
            )}
            <Stack>{props.children}</Stack>
        </Inline>
    )

    return (
        <StyledActionMenuRadioItem {...props} onSelect={(event) => event.preventDefault()}>
            {children}
            <ActionMenu.ItemIndicator />
        </StyledActionMenuRadioItem>
    )
}

export const ActionMenu = ({
    children,
    modal = false,
    ...rest
}: RadixDropdownMenu.DropdownMenuProps) => {
    return (
        <RadixDropdownMenu.Root modal={modal} {...rest}>
            {children}
        </RadixDropdownMenu.Root>
    )
}

ActionMenu.Trigger = ActionMenuTrigger
ActionMenu.Separator = ActionMenuSeparator
ActionMenu.Content = ActionMenuContent
ActionMenu.Item = ActionMenuItem
ActionMenu.RadioGroup = ActionMenuRadioGroup
ActionMenu.RadioItem = ActionMenuRadioItem
ActionMenu.ItemIndicator = ActionMenuItemIndicator

import {useCallback, useEffect, useRef, useState, useLayoutEffect} from 'react'

export interface UseImageProps {
    /**
     * The image `src` attribute
     */
    src?: string
    /**
     * A callback for when the image `src` has been loaded
     */
    onLoad?(event: React.SyntheticEvent<HTMLImageElement, Event>): void
    /**
     * A callback for when there was an error loading the image `src`
     */
    onError?(error: string | React.SyntheticEvent<HTMLImageElement, Event>): void
}

type Status = 'loading' | 'failed' | 'pending' | 'loaded'

type ImageEvent = React.SyntheticEvent<HTMLImageElement, Event>

/**
 * React hook that loads an image in the browser,
 * and let's us know the `status` so we can show image
 * fallback if it is still `pending`
 *
 * @returns the status of the image loading progress
 *
 * @example
 *
 * ```jsx
 * function App(){
 *   const status = useImage({ src: "image.png" })
 *   return status === "loaded" ? <img src="image.png" /> : <Placeholder />
 * }
 * ```
 */
export function useImage(props: UseImageProps) {
    const {src, onLoad, onError} = props

    const [status, setStatus] = useState<Status>('pending')

    useEffect(() => {
        setStatus(src ? 'loading' : 'pending')
    }, [src])

    const imageRef = useRef<HTMLImageElement | null>()

    const load = useCallback(() => {
        if (!src) {
            return
        }

        clearRef()

        const img = new Image()

        img.src = src

        img.onload = (event) => {
            clearRef()
            setStatus('loaded')
            onLoad?.(event as unknown as ImageEvent)
        }

        img.onerror = (error) => {
            clearRef()
            setStatus('failed')
            onError?.(error as any)
        }

        imageRef.current = img
    }, [src, onLoad, onError])

    const clearRef = () => {
        if (imageRef.current) {
            imageRef.current.onload = null
            imageRef.current.onerror = null
            imageRef.current = null
        }
    }

    useLayoutEffect(() => {
        if (status === 'loading') {
            load()
        }
        return () => {
            clearRef()
        }
    }, [status, load])

    return status
}

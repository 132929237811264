import {ErrorMessage, useField} from 'formik'
import React, {FC} from 'react'

import {FormControl} from '../form-control'
import {Textarea, TextareaProps} from '../textarea'

export const FormikTextarea: FC<TextareaProps> = (props: TextareaProps) => {
    const [field, meta] = useField(props.name)

    return (
        <Textarea
            isInvalid={!!(meta.error && meta.touched)}
            renderError={() => <ErrorMessage name={props.name} component={FormControl.Error} />}
            data-telescope="formik-textarea"
            {...field}
            {...props}
        />
    )
}

FormikTextarea.displayName = 'FormikTextarea'

import {ErrorMessage, useField} from 'formik'
import React, {FC} from 'react'

import {FormControl} from '../form-control'
import {InputProps, Input} from '../input'

export const FormikInput: FC<InputProps> = (props) => {
    const [field, meta] = useField(props.name)

    return (
        <Input
            isInvalid={!!(meta.error && meta.touched)}
            renderError={() => <ErrorMessage name={props.name} component={FormControl.Error} />}
            data-telescope="formik-input"
            {...field}
            {...props}
        />
    )
}

FormikInput.displayName = 'FormikInput'

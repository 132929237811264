import * as React from 'react'

type RegisterTrigger = ({
    dialogId,
    triggerId,
    triggerRef
}: {
    dialogId: string
    triggerId: string
    triggerRef: React.RefObject<HTMLElement>
}) => void

type UnregisterTrigger = ({dialogId, triggerId}: {dialogId: string; triggerId: string}) => void

type SetIsDialogOpen = ({dialogId, isOpen}: {dialogId: string; isOpen: boolean}) => void

type GetIsDialogOpen = (dialogId: string) => boolean

type RefocusTrigger = ({dialogId}: {dialogId: string}) => void

type RegisterTriggerInteraction = ({
    dialogId,
    triggerId
}: {
    dialogId: string
    triggerId: string
}) => void

type TriggerRegistry = {
    [dialogId: string]: {
        triggers: {triggerId: string; triggerRef: React.RefObject<HTMLElement>}[]
        triggerToRefocusId: string | null
        isOpen?: boolean
    }
}

interface State {
    triggerRegistry: TriggerRegistry
}

type Action =
    | {
          type: 'REGISTER_TRIGGER'
          payload: {
              dialogId: string
              triggerId: string
              triggerRef: React.RefObject<HTMLElement>
          }
      }
    | {
          type: 'REGISTER_TRIGGER_INTERACTION'
          payload: {
              dialogId: string
              triggerId: string
          }
      }
    | {
          type: 'UNREGISTER_TRIGGER'
          payload: {
              dialogId: string
              triggerId: string
          }
      }
    | {
          type: 'SET_IS_DIALOG_OPEN'
          payload: {
              dialogId: string
              isOpen: boolean
          }
      }

interface DialogTriggerContextValue {
    registerTrigger: RegisterTrigger
    unregisterTrigger: UnregisterTrigger
    setIsDialogOpen: SetIsDialogOpen
    getIsDialogOpen: GetIsDialogOpen
    refocusTrigger: RefocusTrigger
    registerTriggerInteraction: RegisterTriggerInteraction
    triggerRegistry: TriggerRegistry
}

const DialogTriggerContext = React.createContext<DialogTriggerContextValue | undefined>(undefined)

function reducer(state: State, action: Action) {
    const {type, payload} = action
    const {dialogId} = payload

    switch (type) {
        case 'REGISTER_TRIGGER': {
            const {triggerId, triggerRef} = payload
            return {
                ...state,
                triggerRegistry: {
                    ...state.triggerRegistry,
                    [dialogId]: {
                        ...state.triggerRegistry[dialogId],
                        triggers: state.triggerRegistry[dialogId]?.triggers
                            ? [
                                  ...state.triggerRegistry[dialogId].triggers,
                                  {
                                      triggerId,
                                      triggerRef
                                  }
                              ]
                            : [
                                  {
                                      triggerId,
                                      triggerRef
                                  }
                              ]
                    }
                }
            }
        }
        case 'REGISTER_TRIGGER_INTERACTION': {
            const {triggerId} = payload
            return {
                ...state,
                triggerRegistry: {
                    ...state.triggerRegistry,
                    [dialogId]: {
                        ...state.triggerRegistry[dialogId],
                        triggerToRefocusId: triggerId
                    }
                }
            }
        }
        case 'UNREGISTER_TRIGGER': {
            const {triggerId} = payload
            const dialog = state.triggerRegistry[dialogId]

            return {
                ...state,
                triggerRegistry: {
                    ...state.triggerRegistry,
                    [dialogId]: {
                        triggers: dialog.triggers.filter(
                            (trigger) => trigger.triggerId !== triggerId
                        ),
                        triggerToRefocusId:
                            dialog.triggerToRefocusId === action.payload.triggerId
                                ? null
                                : dialog.triggerToRefocusId
                    }
                }
            }
        }
        case 'SET_IS_DIALOG_OPEN': {
            return {
                ...state,
                triggerRegistry: {
                    ...state.triggerRegistry,
                    [dialogId]: {
                        ...state.triggerRegistry[dialogId],
                        isOpen: payload.isOpen
                    }
                }
            }
        }
        default:
            return state
    }
}

interface DialogTriggerContextProviderProps {
    children: React.ReactNode
}

export function DialogTriggerContextProvider({children}: DialogTriggerContextProviderProps) {
    const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(reducer, {
        triggerRegistry: {}
    })

    const registerTrigger: RegisterTrigger = React.useCallback(
        ({dialogId, triggerId, triggerRef}) => {
            dispatch({
                type: 'REGISTER_TRIGGER',
                payload: {dialogId, triggerId, triggerRef}
            })
        },
        []
    )

    const unregisterTrigger: UnregisterTrigger = React.useCallback(({dialogId, triggerId}) => {
        dispatch({
            type: 'UNREGISTER_TRIGGER',
            payload: {dialogId, triggerId}
        })
    }, [])

    const registerTriggerInteraction: RegisterTriggerInteraction = React.useCallback(
        ({dialogId, triggerId}) => {
            dispatch({
                type: 'REGISTER_TRIGGER_INTERACTION',
                payload: {dialogId, triggerId}
            })
        },
        []
    )

    const setIsDialogOpen: SetIsDialogOpen = React.useCallback(({dialogId, isOpen}) => {
        dispatch({
            type: 'SET_IS_DIALOG_OPEN',
            payload: {dialogId, isOpen}
        })
    }, [])

    const getIsDialogOpen: GetIsDialogOpen = React.useCallback(
        (dialogId) => state.triggerRegistry[dialogId]?.isOpen || false,
        [state.triggerRegistry]
    )

    const refocusTrigger: RefocusTrigger = React.useCallback(
        ({dialogId}) => {
            let triggerToRefocus: React.RefObject<HTMLElement> | null = null

            // Use the last interacted trigger if it is still mounted
            if (state.triggerRegistry[dialogId]?.triggerToRefocusId) {
                const lastInteractedTrigger = state.triggerRegistry[dialogId]?.triggers.find(
                    (trigger) =>
                        trigger.triggerId === state.triggerRegistry[dialogId]?.triggerToRefocusId
                )?.triggerRef

                if (lastInteractedTrigger?.current) {
                    triggerToRefocus = lastInteractedTrigger
                }
            } else {
                // If the last interacted trigger is not mounted, use the first registered trigger in the list
                const firstTrigger = state.triggerRegistry[dialogId]?.triggers?.[0]?.triggerRef
                if (firstTrigger?.current) {
                    triggerToRefocus = firstTrigger
                }
            }

            if (triggerToRefocus && triggerToRefocus.current) {
                triggerToRefocus.current.focus()
            } else {
                // If no triggers are mounted, log a warning (in development only)
                if (process.env.NODE_ENV === 'development') {
                    console.warn(
                        `No trigger found for Dialog with dialogId: ${dialogId}. Please make sure the Dialog has a trigger with a matching dialogId.`
                    )
                }
            }
        },
        [state.triggerRegistry]
    )

    return (
        <DialogTriggerContext.Provider
            value={{
                registerTrigger,
                unregisterTrigger,
                setIsDialogOpen,
                getIsDialogOpen,
                registerTriggerInteraction,
                refocusTrigger,
                triggerRegistry: state.triggerRegistry
            }}
        >
            {children}
        </DialogTriggerContext.Provider>
    )
}

export function useDialogTriggerContext() {
    const context = React.useContext(DialogTriggerContext)

    if (!context) {
        throw new Error(
            'useDialogTriggerContext must be used within a DialogTriggerContextProvider'
        )
    }

    return context
}

import React from 'react'

import {AVATAR_DEFAULT_SIZE} from './avatar'
import * as s from './avatar-group.styles'
import {AvatarCircleExcess} from './avatar.styles'
import type {AvatarSize} from './types'

export interface AvatarGroupProps {
    /*
     * The maximum number of avatars to be shown in the group. Excess avatars will be represented  with a number.
     */
    max?: number
    /*
     * Whether avatars should be stacked on top of each other
     */
    stacked?: boolean
    /*
     * Size applied to all `Avatar` components nested within a group
     */
    size?: AvatarSize
    /*
     * Any number of `Avatar` components
     */
    children: React.ReactNode
}

function getValidChildren(children: React.ReactNode) {
    return React.Children.toArray(children).filter((child) =>
        React.isValidElement(child)
    ) as React.ReactElement[]
}

export const AvatarGroup = ({
    max,
    stacked,
    size = AVATAR_DEFAULT_SIZE,
    children
}: AvatarGroupProps) => {
    const validChildren = getValidChildren(children)

    const childrenWithinMax = max ? validChildren.slice(0, max) : validChildren

    const excess = max !== undefined ? validChildren.length - max : 0

    const childrenExtended = childrenWithinMax.map((child) => {
        return React.cloneElement(child, {
            size,
            outlined: stacked
        })
    })

    return (
        <s.AvatarGroup $size={size} $stacked={stacked} data-telescope="avatar-group">
            {childrenExtended}
            {excess > 0 && (
                <AvatarCircleExcess $size={size} $outlined={stacked} data-telescope-avatar-excess>
                    +{excess}
                </AvatarCircleExcess>
            )}
        </s.AvatarGroup>
    )
}

import React from 'react'
import styled from 'styled-components'

import {Check} from '@pleo-io/telescope-icons'

import {SelectTypes} from './select'

import {tokens} from '../../tokens'
import {Avatar} from '../avatar'

export type SelectOptionProps = {
    isSelected?: boolean
    isFocused?: boolean
    maxWidth?: string
    children: React.ReactNode
    innerRef: React.Ref<HTMLDivElement>
    innerProps: React.HTMLAttributes<HTMLDivElement>
    avatar?: SelectTypes.Avatar
    icon?: React.ReactElement | string
    description?: string
    disabled?: boolean
}

type StyledOptionProps = {
    $isFocused?: boolean
    $isDisabled?: boolean
    $maxWidth?: string
    $hasAvatar?: boolean
}

const getBackgroundColor = ({$isFocused, $isDisabled}: StyledOptionProps) => {
    if ($isDisabled) {
        return tokens.colorBackgroundInteractiveDisabled
    } else if ($isFocused) {
        return tokens.colorBackgroundInteractiveHover
    }

    return tokens.colorBackgroundInteractive
}

const getTextColor = ({$isFocused, $isDisabled}: StyledOptionProps) => {
    if ($isDisabled) {
        return tokens.colorContentInteractiveDisabled
    } else if ($isFocused) {
        return tokens.colorContentInteractiveHover
    }

    return tokens.colorContentInteractive
}

const StyledOption = styled.div<StyledOptionProps>`
    position: relative;
    display: flex;
    align-items: center;
    gap: ${({$hasAvatar}: StyledOptionProps) =>
        $hasAvatar ? `${tokens.spacing12}` : `${tokens.spacing8}`};
    max-width: ${({$maxWidth}) => $maxWidth};
    min-height: auto;
    padding: ${tokens.spacing8} ${tokens.spacing12};
    color: ${getTextColor};
    font-size: ${tokens.fontMedium};
    line-height: ${tokens.lineHeight2};
    background-color: ${getBackgroundColor};
    cursor: ${({$isDisabled}: StyledOptionProps) => ($isDisabled ? 'not-allowed' : 'pointer')};
`

const getDescriptionColor = ({$isFocused, $isDisabled}: StyledOptionProps) => {
    if ($isDisabled) {
        return tokens.colorContentInteractiveDisabled
    } else if ($isFocused) {
        return tokens.colorContentInteractiveHover
    }

    return tokens.colorContentInteractiveQuiet
}

const Description = styled.span<StyledOptionProps>`
    color: ${getDescriptionColor};
    font-size: ${tokens.fontSmall};
    line-height: ${tokens.lineHeight1};
`

export const Prefix = styled.span`
    align-self: start;
    line-height: 0;

    [data-telescope='icon'] {
        width: 16px;
        height: 16px;
        /* stylelint-disable-next-line declaration-property-value-allowed-list */
        margin-top: 3px;
    }
`

const SelectedIcon = styled(Check).attrs({size: 16})`
    align-self: center;
    justify-self: center;
    margin-left: auto;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing2};
    align-items: start;
    overflow: hidden;
`

export const SelectOption = ({
    isSelected,
    isFocused,
    maxWidth,
    children,
    innerRef,
    innerProps,
    avatar,
    icon,
    description,
    disabled
}: SelectOptionProps) => {
    let prefix
    if (avatar) {
        prefix = <Avatar {...avatar} size={avatar.size ?? 24} css={{opacity: disabled ? 0.5 : 1}} />
    } else if (icon) {
        prefix = icon
    }
    return (
        <StyledOption
            {...innerProps}
            ref={innerRef}
            $isDisabled={disabled}
            $isFocused={isFocused}
            $maxWidth={maxWidth}
            $hasAvatar={!!avatar}
        >
            {prefix && <Prefix>{prefix}</Prefix>}
            <Content>
                {children}
                {description && (
                    <Description $isDisabled={disabled} $isFocused={isFocused}>
                        {description}
                    </Description>
                )}
            </Content>
            {isSelected && <SelectedIcon />}
        </StyledOption>
    )
}

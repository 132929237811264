import React, {FC} from 'react'

import {
    ComponentWrapper,
    BaseInput,
    InputProps,
    Postfix,
    Prefix,
    InputWrapper,
    handleInputFocus
} from './base-components'

import {useFormControlId, useFormControlCaptionId, Label} from '../form-control'
import {Text} from '../text'

export const Input: FC<InputProps> = ({
    className,
    postfix,
    prefix,
    isInvalid = false,
    label,
    renderError,
    renderInput,
    variant,
    innerRef,
    ...props
}) => {
    const id = useFormControlId(props.name)
    const captionId = useFormControlCaptionId()
    const ariaProps = {
        id,
        'aria-invalid': isInvalid,
        'aria-label': label ? label : undefined,
        'aria-describedby': captionId
    }
    const alternateRef = React.useRef<HTMLInputElement>(null)
    const inputRef = innerRef ?? alternateRef

    return (
        <ComponentWrapper className={className} data-telescope="input">
            {label && (
                <Label
                    // Will be fixed in https://linear.app/pleo/issue/WEB-789/replace-react-docgen-typescript-with-react-docgen
                    // @ts-expect-error htmlFor prop missing
                    htmlFor={id}
                    as="label"
                    data-input-label
                >
                    {label}
                </Label>
            )}
            <InputWrapper
                isInvalid={isInvalid}
                variant={variant}
                disabled={props.disabled}
                data-input-wrapper
                onClick={(e) => handleInputFocus(e, inputRef, !!props.disabled)}
            >
                <Prefix data-input-prefix>
                    {typeof prefix === 'string' ? (
                        <Text
                            as="span"
                            color={
                                props.disabled
                                    ? 'colorContentInteractiveDisabled'
                                    : 'colorContentInteractiveQuiet'
                            }
                        >
                            {prefix}
                        </Text>
                    ) : (
                        prefix
                    )}
                </Prefix>

                {renderInput ? (
                    renderInput(ariaProps, props)
                ) : (
                    <BaseInput {...props} {...ariaProps} ref={inputRef} data-input-base />
                )}
                <Postfix data-input-postfix>
                    {typeof postfix === 'string' ? (
                        <Text
                            as="span"
                            color={
                                props.disabled
                                    ? 'colorContentInteractiveDisabled'
                                    : 'colorContentInteractiveQuiet'
                            }
                        >
                            {postfix}
                        </Text>
                    ) : (
                        postfix
                    )}
                </Postfix>
            </InputWrapper>

            {renderError && renderError()}
        </ComponentWrapper>
    )
}

Input.displayName = 'Input'

import {useId} from '@reach/auto-id'
import React, {HTMLAttributes} from 'react'
import styled, {keyframes} from 'styled-components'
import {size, space, SpaceProps, height, HeightProps, width, WidthProps} from 'styled-system'

import {tokens, customColorSchemeTokens} from '../../tokens'
import {Text} from '../text'

import type {} from 'csstype'

export interface LoadingProps
    extends SpaceProps,
        HeightProps,
        WidthProps,
        Omit<HTMLAttributes<HTMLDivElement>, 'color'> {
    /** Size (in pixels) of each dot. */
    size?: number
    /** Background color of each dot. */
    color?: string
}

export const Loading = ({
    height: dotsHeight,
    width: dotsWidth,
    size: dotSize,
    color,
    ...props
}: LoadingProps) => (
    <Dots
        height={dotsHeight}
        width={dotsWidth}
        role="progressbar"
        aria-live="polite"
        data-telescope="loading"
        {...props}
    >
        <Dot $delay={0} size={dotSize} $color={color} />
        <Dot $delay={1} size={dotSize} $color={color} />
        <Dot $delay={2} size={dotSize} $color={color} />
    </Dots>
)

Loading.displayName = 'Loading'

const defaultProps = {
    size: 20,
    color: customColorSchemeTokens.colorBackgroundLoading
}

Loading.defaultProps = defaultProps

export const LoadingPage = ({children, ...props}: LoadingProps) => {
    const id = useId()
    return (
        <PageContainer data-telescope="loading-page">
            <Loading {...props} aria-labelledby={id} />
            <Text id={id} variant="medium-default">
                {children}
            </Text>
        </PageContainer>
    )
}

LoadingPage.displayName = 'LoadingPage'

LoadingPage.defaultProps = {
    ...defaultProps,
    color: customColorSchemeTokens.colorBackgroundLoading
}

export const LoadingOverlay = ({children, ...props}: LoadingProps) => {
    const id = useId()
    return (
        <OverlayContainer data-telescope="loading-overlay">
            <Loading {...props} aria-labelledby={id} />
            <Text id={id} variant="medium-default">
                {children}
            </Text>
        </OverlayContainer>
    )
}

LoadingOverlay.displayName = 'LoadingOverlay'

LoadingOverlay.defaultProps = {
    ...defaultProps,
    color: customColorSchemeTokens.colorBackgroundLoading
}

const animation = keyframes`
    0%,
    80%,
    100% {
        transform: scale3d(0.3, 0.3, 1);
    }

    40% {
        transform: scale3d(1.2, 1.2, 1);
    }
`

export const Dots = styled.div.withConfig({
    shouldForwardProp: (prop: string) =>
        ![...height.propNames!, ...width.propNames!, space.propNames!].includes(prop)
})<HeightProps & WidthProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    ${height}
    ${width}
    ${space}
`

Dots.displayName = 'Dots'

const PageContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;

    ${Dots} {
        padding-top: ${tokens.spacing60};
        padding-bottom: ${tokens.spacing60};
    }
`

const OverlayContainer = styled.div<{size?: number}>`
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);

    ${Dots} {
        padding-top: ${tokens.spacing60};
        padding-bottom: ${tokens.spacing60};
    }
`

export const Dot = styled.div.withConfig({
    shouldForwardProp: (prop: string) => !size.propNames!.includes(prop)
})<{
    size?: number
    $delay?: number
    $color?: string
}>`
    display: inline-block;
    margin-right: ${tokens.spacing20};
    background-color: ${({$color}) => $color};
    border-radius: ${tokens.circle};
    animation: ${animation} 1.2s infinite ease-in-out;
    animation-delay: ${({$delay = 0}) => $delay * 160}ms;
    animation-fill-mode: both;
    ${size}

    &:last-child {
        margin-right: 0;
    }
`

Dot.displayName = 'Dot'

Dot.defaultProps = {
    color: tokens.colorContentStaticQuiet
}

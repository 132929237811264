import {
    Root,
    Thumb as RadixThumb,
    SwitchProps as RadixSwitchProps,
    SwitchThumbProps
} from '@radix-ui/react-switch'
import React from 'react'
import styled from 'styled-components'

import {CircleCheckInner, CircleCloseInner} from '@pleo-io/telescope-icons'

import {tokens, customColorSchemeTokens} from '../../tokens'
import {focusRing} from '../focus-ring'
import {Stack} from '../stack'
import {Text} from '../text'

const getBackgroundColor = ({checked}: {checked: boolean}) => {
    return checked
        ? customColorSchemeTokens.colorBackgroundSwitchTrackOn
        : customColorSchemeTokens.colorBackgroundSwitchTrackOff
}

const Input = styled(Root)<RadixSwitchProps>`
    ${focusRing('offset')};
    margin: 1px 0 0;
    padding: 0;
    flex-shrink: 0;
    width: 34px;
    height: 20px;
    cursor: pointer;
    background-color: ${() => getBackgroundColor({checked: false})};
    border: none;
    border-radius: ${tokens.arc20};
    transition: ${tokens.motionWithinSmallShort};
    transition-property: background-color;
    outline: none;
    appearance: none;

    &[data-state='checked'] {
        background-color: ${() => getBackgroundColor({checked: true})};
    }

    &:disabled {
        background-color: ${customColorSchemeTokens.colorBackgroundSwitchTrackDisabled};
        cursor: not-allowed;
    }

    &:active {
        transform: none;
    }
`

const Thumb = styled(RadixThumb)<SwitchThumbProps>`
    position: relative;
    left: ${tokens.spacing2};
    display: flex;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    color: ${customColorSchemeTokens.colorBackgroundSwitchTrackOff};
    background-color: ${tokens.colorBackgroundInteractive};
    border-radius: ${tokens.circle};
    transition: ${tokens.motionWithinSmallShort};
    transition-property: left, border-color;
    pointer-events: none;

    &[data-state='checked'] {
        left: 16px;
        color: ${customColorSchemeTokens.colorBackgroundSwitchTrackOn};
    }

    &[data-disabled] {
        color: ${customColorSchemeTokens.colorContentSwitchThumbDisabled};
        background-color: ${customColorSchemeTokens.colorBackgroundSwitchThumbDisabled};
    }
`

const Container = styled(Text)<{
    disabled?: boolean
}>`
    display: inline-flex;
    flex-direction: row;
    gap: ${tokens.spacing12};
    justify-content: center;
    color: ${({disabled}) =>
        disabled ? tokens.colorContentInteractiveDisabled : tokens.colorContentInteractive};
    cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};

    &:hover:not([disabled]) {
        color: ${tokens.colorContentInteractiveHover};
    }
`

const Content = styled(Stack).attrs({space: 2})``
const Description = ({children}: any) => (
    <Text css={{display: 'block'}} variant="small-subtle">
        {children}
    </Text>
)

interface SwitchProps extends RadixSwitchProps {
    /**
     * The label to display next to the switch. Keep it short.
     */
    children?: React.ReactNode
    /**
     * Callback that is called when the switch is clicked.
     */
    onCheckedChange?: (checked: boolean) => void
    /**
     * Whether the switch is checked or not.
     */
    checked?: boolean
    /**
     * If the switch is aligned to the right, the label should be aligned to the left and vice versa.
     * @default 'right'
     */
    labelPosition?: 'right' | 'left'
    /**
     * A description shown below the label of the switch.
     */
    description?: string
}
export const Switch = ({children, labelPosition = 'right', description, ...props}: SwitchProps) => {
    const content = description ? (
        <Content>
            {children}
            <Description>{description}</Description>
        </Content>
    ) : (
        children
    )
    return (
        <Container disabled={props.disabled} as={children ? 'label' : 'div'}>
            {labelPosition === 'left' && content}
            <Input {...props}>
                <Thumb>
                    {props.checked ? (
                        <CircleCheckInner size={16} />
                    ) : (
                        <CircleCloseInner size={16} />
                    )}
                </Thumb>
            </Input>
            {labelPosition === 'right' && content}
        </Container>
    )
}

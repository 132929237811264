import React, {HTMLAttributes} from 'react'
import styled from 'styled-components'

import {tokens} from '../../tokens'
import {ButtonGroup} from '../button'
import {Stack, StackProps} from '../stack'
import {Text} from '../text'

export const cardHorizontalSpacing = tokens.spacing32
export const cardVerticalSpacing = tokens.spacing24

interface CardProps {
    /** The contents of the Card */
    children: React.ReactNode
}

export const Card = ({
    children,
    ...props
}: CardProps & HTMLAttributes<HTMLDivElement> & Omit<StackProps, 'border' | 'borderRadius'>) => {
    return (
        <BaseCard data-telescope="card" {...props}>
            {children}
        </BaseCard>
    )
}

const BaseCard = styled(Stack).attrs<StackProps>((props) => ({
    justifyItems: props.justifyItems || 'stretch'
}))`
    width: 100%;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    background: ${tokens.colorBackgroundStatic};
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};
    border-radius: ${tokens.arc8};
`

interface PictogramProps {
    /** The svg of the pictogram */
    children: React.ReactNode

    /** Whether the pictogram's container should have top padding
     * @default true
     */
    hasSpacingTop?: boolean

    /** Whether the pictogram's container should have bottom padding
     * @default false
     */
    hasSpacingBottom?: boolean
}

const Pictogram = ({
    children,
    hasSpacingTop = true,
    hasSpacingBottom = false,
    ...props
}: PictogramProps & HTMLAttributes<HTMLDivElement>) => {
    return (
        <StyledPictogram
            $hasSpacingTop={hasSpacingTop}
            $hasSpacingBottom={hasSpacingBottom}
            {...props}
        >
            {children}
        </StyledPictogram>
    )
}

const StyledPictogram = styled.div<{$hasSpacingTop?: Boolean; $hasSpacingBottom?: Boolean}>`
    padding-inline: ${cardHorizontalSpacing};
    padding-top: ${({$hasSpacingTop}) => ($hasSpacingTop ? `${cardVerticalSpacing}` : '0')};
    padding-bottom: ${({$hasSpacingBottom}) =>
        $hasSpacingBottom ? `${cardVerticalSpacing}` : '0'};

    svg,
    img {
        height: 80px;
    }
`
interface HeaderProps {
    /** The contents of the Header */
    children: React.ReactNode

    /**
     * Whether the Header should have top padding
     * @default true
     */
    hasSpacingTop?: boolean

    /**
     * Whether the Header should have bottom padding
     * @default false
     */
    hasSpacingBottom?: boolean
}

const Header = ({
    children,
    hasSpacingTop = true,
    hasSpacingBottom = false,
    ...props
}: HeaderProps & HTMLAttributes<HTMLDivElement>) => {
    return (
        <StyledHeader
            $hasSpacingTop={hasSpacingTop}
            $hasSpacingBottom={hasSpacingBottom}
            {...props}
        >
            {children}
        </StyledHeader>
    )
}

const StyledHeader = styled.div<{$hasSpacingTop?: Boolean; $hasSpacingBottom?: Boolean}>`
    padding-inline: ${cardHorizontalSpacing};
    padding-top: ${({$hasSpacingTop}) => ($hasSpacingTop ? `${cardVerticalSpacing}` : '0')};
    padding-bottom: ${({$hasSpacingBottom}) =>
        $hasSpacingBottom ? `${cardVerticalSpacing}` : '0'};
`

interface TitleProps {
    /** The contents of the Title */
    children: React.ReactNode

    /** The heading level to use for the title */
    headingLevel: 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const Title = ({
    children,
    headingLevel,
    ...props
}: TitleProps & Omit<HTMLAttributes<HTMLHeadingElement>, 'color'>) => {
    return (
        <Text variant="xlarge-accent" as={headingLevel} {...props}>
            {children}
        </Text>
    )
}

interface SubtitleProps {
    /** The contents of the Subtitle */
    children: React.ReactNode
}

const Subtitle = ({
    children,
    ...props
}: SubtitleProps & Omit<HTMLAttributes<HTMLParagraphElement>, 'color'>) => {
    return (
        <Text as="p" variant="medium-default" color="colorContentStaticQuiet" {...props}>
            {children}
        </Text>
    )
}

interface BodyProps {
    /** The contents of the Body */
    children: React.ReactNode

    /** Whether the Body should have top padding
     * @default true
     */
    hasSpacingTop?: boolean

    /** Whether the Body should have bottom padding
     * @default true
     */
    hasSpacingBottom?: boolean
}

const Body = ({
    children,
    hasSpacingTop = true,
    hasSpacingBottom = true,
    ...props
}: BodyProps & HTMLAttributes<HTMLDivElement>) => {
    return (
        <StyledBody $hasSpacingTop={hasSpacingTop} $hasSpacingBottom={hasSpacingBottom} {...props}>
            {children}
        </StyledBody>
    )
}

const StyledBody = styled.div<{$hasSpacingTop?: Boolean; $hasSpacingBottom?: Boolean}>`
    padding-inline: ${cardHorizontalSpacing};
    padding-top: ${({$hasSpacingTop}) => ($hasSpacingTop ? `${cardVerticalSpacing}` : '0')};
    padding-bottom: ${({$hasSpacingBottom}) =>
        $hasSpacingBottom ? `${cardVerticalSpacing}` : '0'};
`

interface FooterProps {
    /** The contents of the Footer */
    children: React.ReactNode

    /** Whether the Footer should have top padding
     * @default true
     */
    hasSpacingTop?: boolean

    /** Whether the Footer should have bottom padding
     * @default true
     */
    hasSpacingBottom?: boolean
}

const Footer = ({
    children,
    hasSpacingTop = true,
    hasSpacingBottom = true,
    ...props
}: FooterProps & HTMLAttributes<HTMLDivElement>) => {
    return (
        <StyledFooter
            $hasSpacingTop={hasSpacingTop}
            $hasSpacingBottom={hasSpacingBottom}
            {...props}
        >
            {children}
        </StyledFooter>
    )
}

const StyledFooter = styled.div<{$hasSpacingTop?: Boolean; $hasSpacingBottom?: Boolean}>`
    padding-inline: ${cardHorizontalSpacing};
    padding-top: ${({$hasSpacingTop}) => ($hasSpacingTop ? `${cardVerticalSpacing}` : '0')};
    padding-bottom: ${({$hasSpacingBottom}) =>
        $hasSpacingBottom ? `${cardVerticalSpacing}` : '0'};
`

const Divider = styled.div`
    width: 100%;
    height: ${tokens.sizeBorderDefault};
    background-color: ${tokens.colorBorderStatic};
`
const FooterActions = styled(ButtonGroup).attrs({justifyContent: 'right'})``

Card.Pictogram = Pictogram
Card.Header = Header
Card.Title = Title
Card.Subtitle = Subtitle
Card.Body = Body
Card.Footer = Footer
Card.FooterActions = FooterActions
Card.Divider = Divider

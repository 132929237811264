import styled, {css} from 'styled-components'

import {tokens, customColorSchemeTokens} from '../../tokens'

export type BarVariant = 'slim' | 'tall' | 'square'
export type BarFillColor = 'green500' | 'pink500' | 'shade900' | 'red600' | 'pink300'
export type BarBgColor = 'red600' | 'shade300' | 'none'

const DEFAULT_FILL = 'green500'
const DEFAULT_BG = 'shade300'

interface ProgressBarBaseProps {
    /** Number from 0 to 100. Numbers beyond limits will be capped automatically */
    percent: number
    fillColor?: BarFillColor
    bgColor?: BarBgColor
    variant: BarVariant
}

/*
 * For a11y, enforce either `aria-describedby`, `aria-labelledby` or `aria-label` props.
 */
interface ProgressBarDescribedBar extends ProgressBarBaseProps {
    /**
     * See [MDN aria-describedby](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/
     */
    'aria-describedby': string
}

interface ProgressBarLabelledBy extends ProgressBarBaseProps {
    /**
     * See [MDN documentation aria-labelledby](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-labelledby_attribute)
     */
    'aria-labelledby': string
}

interface ProgressBarLabel extends ProgressBarBaseProps {
    /**
     * See [MDN aria-label](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-label_attribute)
     */
    'aria-label': string
}

export type ProgressBarProps = ProgressBarDescribedBar | ProgressBarLabelledBy | ProgressBarLabel

const handleBackgroundColor = (color: BarBgColor) => {
    switch (color) {
        case 'shade300':
            return customColorSchemeTokens.colorBackgroundProgressBarShade300
        case 'none':
            return 'transparent'
        default:
            return tokens[color]
    }
}

const handleFillColor = (color: BarFillColor) => {
    switch (color) {
        case 'shade900':
            return customColorSchemeTokens.colorContentProgressBarShade900
        case 'pink300':
            return customColorSchemeTokens.colorContentProgressBarPink300
        default:
            return tokens[color]
    }
}

const tallStyles = css`
    height: 10px;

    &::before,
    &::after {
        border-radius: ${tokens.arc8};
    }
`

const slimStyles = css<ProgressBarProps>`
    height: 8px;

    &::before {
        border-top-left-radius: ${tokens.arc8};
        border-bottom-left-radius: ${tokens.arc8};
    }

    &::after {
        position: absolute;
        top: -2px;
        left: ${({percent}) => `${percent || '0'}%`};
        display: ${({percent}) => (percent ? 'block' : 'none')};
        width: 2px;
        height: 12px;
        background-color: ${({fillColor = DEFAULT_FILL}) => handleFillColor(fillColor)};
        border-radius: ${tokens.arc8};
        content: '';
    }
`

const squareStyles = css`
    height: 6px;
    border-radius: 0;

    &::before {
        border-radius: 0;
    }
`

const BarStyles = css<ProgressBarProps>`
    position: relative;
    width: 100%;
    background-color: ${({bgColor = DEFAULT_BG}) =>
        bgColor === 'none' ? 'transparent' : handleBackgroundColor(bgColor)};
    border-radius: ${tokens.arc8};

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: ${({percent}) => `${percent || '0'}%`};
        height: 100%;
        background-color: ${({fillColor = DEFAULT_FILL}) => handleFillColor(fillColor)};
        transition: ${tokens.motionWithinSmallLong};
        transition-property: width;
        content: '';
    }
`

const getVariantStyles = (props: ProgressBarProps) => {
    if (props.variant === 'slim') {
        return slimStyles
    } else if (props.variant === 'tall') {
        return tallStyles
    } else if (props.variant === 'square') {
        return squareStyles
    }

    return BarStyles
}

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max)

export const ProgressBar = styled.div
    .withConfig({
        shouldForwardProp: (prop: string) =>
            !['percent', 'fillColor', 'bgColor', 'variant'].includes(prop)
    })
    .attrs<ProgressBarProps>(({percent, fillColor = DEFAULT_FILL, bgColor = DEFAULT_BG}) => {
        const volume = clamp(percent, 0, 100)
        return {
            role: 'progressbar',
            'aria-valuenow': volume,
            'aria-valuemin': 0,
            'aria-valuemax': 100,
            percent: volume,
            fillColor,
            bgColor,
            'data-telescope': 'progress-bar'
        }
    })<ProgressBarProps>`
    ${BarStyles};
    ${getVariantStyles};
`

ProgressBar.displayName = 'ProgressBar'

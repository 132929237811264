import {AnimatePresence, motion} from 'framer-motion'
import * as React from 'react'
import styled, {css} from 'styled-components'

import {ArrowLeft} from '@pleo-io/telescope-icons'

import {customColorSchemeTokens, tokens} from '../../tokens'
import {useLocalisation} from '../../utils/localisation'
import {Box, BoxProps} from '../box'
import {Button, ButtonProps} from '../button'
import {NakedButton} from '../button'
import {Modal, ModalProps} from '../modal'
import {Text, TextProps} from '../text'
import {VisuallyHidden} from '../visually-hidden'

const splitBreakpoint = '700px'

const inlinePadding = css`
    padding-inline: ${tokens.spacing40};
`

export const ModalSplit = ({
    children,
    dangerouslySetZIndexValue,
    ...props
}: Omit<ModalProps, 'size'>) => {
    return (
        <StyledModal
            dangerouslySetZIndexValue={dangerouslySetZIndexValue ?? tokens.zIndexOverlay}
            {...props}
            {...(!props['aria-label']
                ? {'aria-labelledby': props['aria-labelledby'] ?? '#modal-split-title'}
                : {})}
        >
            <Modal.Close onClick={props.onDismiss} />
            {children}
        </StyledModal>
    )
}

const StyledModal = styled(Modal).attrs({size: '900px'})`
    box-sizing: border-box;
    height: 85vh;
    display: flex;
    overflow: hidden;
    max-width: 95vw;
    padding-top: 0 !important;

    @media (max-width: ${splitBreakpoint}) {
        flex-direction: column-reverse;
        height: 100%;
    }

    *,
    *::after,
    *::before {
        box-sizing: inherit;
    }
`
export interface ModalContentProps {
    /**
     * The contents of the Modal.
     */
    children?: React.ReactNode
}

const SplitContent = ({children, ...props}: ModalContentProps) => {
    return <Content {...props}>{children}</Content>
}

const SplitTitle = styled(Text).attrs({
    align: 'left',
    variant: '3xlarge-accent',
    as: 'h2',
    id: 'modal-split-title'
})`
    margin-bottom: ${tokens.spacing16};
`

export interface ModalBodyProps {
    /**
     * The contents of the Modal Body
     */
    children?: React.ReactNode
}

const SplitBody = ({children, ...props}: ModalBodyProps & React.HTMLAttributes<HTMLDivElement>) => {
    const containerRef = React.useRef<HTMLDivElement>(null)

    const [isOverflowing, setIsOverflowing] = React.useState(false)
    const [isScrolled, setIsScrolled] = React.useState(false)
    const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false)

    React.useEffect(() => {
        function setOverflow() {
            const container = containerRef?.current
            if (container) {
                const isContainerOverflowing = container.scrollHeight > container.clientHeight
                setIsOverflowing(isContainerOverflowing)
            }
        }
        setOverflow()
        window.addEventListener('resize', setOverflow)

        return () => {
            window.removeEventListener('resize', setOverflow)
        }
    }, [containerRef, setIsOverflowing])

    return (
        <BodyContainer
            ref={containerRef}
            onScroll={(e) => {
                const body = e.currentTarget
                setIsScrolled(body.scrollTop > 0)
                const isScrolledToBottom =
                    Math.abs(body.scrollHeight - body.scrollTop - body.clientHeight) <= 1
                setIsScrolledToBottom(isScrolledToBottom)
            }}
            $isOverflowing={isOverflowing}
            $isScrolled={isScrolled}
            $isScrolledToBottom={isScrolledToBottom}
            {...props}
        >
            {children}
        </BodyContainer>
    )
}

const BodyContainer = styled.div<{
    $isOverflowing: boolean
    $isScrolled: boolean
    $isScrolledToBottom: boolean
}>`
    ${inlinePadding}
    flex: 1 1 auto;
    overflow-y: auto;

    ${({$isScrolled}) => {
        return (
            $isScrolled &&
            css`
                border-top: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};
            `
        )
    }}

    ${({$isOverflowing, $isScrolledToBottom}) => {
        return (
            $isOverflowing &&
            css`
                border-bottom: ${$isScrolledToBottom
                    ? 'none'
                    : `${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic}`};
                padding-bottom: ${tokens.spacing24};
            `
        )
    }}
`

const SplitSectionTitle = styled(Text).attrs<Omit<TextProps, 'variant' | 'weight'>>((props) => ({
    as: props.as || 'h3',
    align: props.align || 'left',
    weight: 'semibold'
}))`
    margin-bottom: ${tokens.spacing8};
`

const SplitText = styled(Text).attrs<Omit<TextProps, 'variant'>>((props) => ({
    as: props.as || 'p',
    align: props.align || 'left'
}))``

const SplitActions = styled(Modal.Actions)`
    padding-top: ${tokens.spacing32};
    padding-bottom: ${tokens.spacing8};
    justify-content: space-between;
    ${inlinePadding}
`

const SplitFooter = styled(Modal.Footer)`
    ${inlinePadding}
`

const BackButton = (
    props: Omit<ButtonProps, 'variant' | 'IconLeft' | 'IconRight' | 'children'>
) => {
    const translations = useLocalisation()

    return (
        <StyledBackButton variant="tertiary" IconLeft={ArrowLeft} {...props}>
            {translations.ModalSplit.BackButton}
        </StyledBackButton>
    )
}

const StyledBackButton = styled(Button)`
    height: 40px;
`

const NextButton = (props: Omit<ButtonProps, 'variant' | 'IconLeft'>) => {
    return <RightAlignedButton variant="primary" {...props} />
}

const RightAlignedButton = styled(Button)`
    margin-left: auto;
`

interface IllustrationContainerProps {
    /**
     * The background colour of the illustration container
     * @default 'gray'
     */
    variant?: 'pink' | 'gray'
    /**
     * The contents of the Illustration container.
     */
    children?: React.ReactNode
}

const IllustrationContainer = styled.div.withConfig({
    shouldForwardProp: (propName) => propName !== 'variant'
})<IllustrationContainerProps>`
    flex: 0 0 50%;
    transition: background-color ${tokens.motionDurationModerate * 2}s;
    background: ${({variant}) =>
        variant === 'pink'
            ? customColorSchemeTokens.colorBackgroundModalSplitIllustrationPink
            : tokens.colorBackgroundPresentationalGray};

    @media (max-width: ${splitBreakpoint}) {
        height: 160px;
        flex: 0 0 160px;
    }
`

interface IllustrationProps
    extends Pick<
        BoxProps,
        | 'p'
        | 'padding'
        | 'px'
        | 'paddingX'
        | 'py'
        | 'paddingY'
        | 'paddingLeft'
        | 'pl'
        | 'paddingRight'
        | 'pr'
        | 'paddingBottom'
        | 'pb'
        | 'paddingTop'
        | 'pt'
    > {
    /**
     * Align the illustration to the top or bottom. Defaults to center.
     */
    verticalAlign?: 'top' | 'bottom'
    /**
     * Align the illustration to the left or right. Defaults to center.
     */
    horizontalAlign?: 'left' | 'right'
    /**
     * The contents of the Illustration. This should only contain an image.
     */
    children?: React.ReactNode
}

const Illustration = styled(Box).withConfig({
    shouldForwardProp: (propName) => propName !== 'verticalAlign' && propName !== 'horizontalAlign'
})<IllustrationProps>`
    display: flex;
    height: 100%;

    ${({verticalAlign}) => {
        if (verticalAlign === 'top') {
            return css`
                align-items: flex-start;
            `
        }
        if (verticalAlign === 'bottom') {
            return css`
                align-items: flex-end;
            `
        }
        return css`
            align-items: center;
        `
    }}

    ${({horizontalAlign}) => {
        if (horizontalAlign === 'left') {
            return css`
                justify-content: flex-start;
            `
        }
        if (horizontalAlign === 'right') {
            return css`
                justify-content: flex-end;
            `
        }
        return css`
            justify-content: center;
        `
    }}

    img,
    svg {
        width: ${({horizontalAlign}) => (horizontalAlign ? 'auto' : '100%')};
        object-fit: contain;
    }

    @media (max-width: ${splitBreakpoint}) {
        img,
        svg {
            height: 100%;
        }
    }
`
const variants = {
    initial: {opacity: 0, transition: {duration: tokens.motionDurationModerate}},
    animate: {opacity: 1, transition: {duration: tokens.motionDurationModerate}},
    exit: {opacity: 0, transition: {duration: tokens.motionDurationModerate}}
}
const AnimatedContent = ({children, key}: {children: React.ReactNode; key: string}) => (
    <motion.div
        style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            overflow: 'hidden'
        }}
        key={key}
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
    >
        {children}
    </motion.div>
)

interface StepProps {
    children: React.ReactNode
    stepId: string
}

const Step = ({children, stepId}: StepProps) => (
    <AnimatedContent key={stepId}>{children}</AnimatedContent>
)

const Steps = ({children, activeStepId}: {children: React.ReactNode; activeStepId: string}) => {
    const childrenArray = React.Children.toArray(children) as React.ReactElement<StepProps>[]
    const activeChild = childrenArray.find((i) => i.props.stepId === activeStepId)

    return <AnimatePresence exitBeforeEnter>{activeChild}</AnimatePresence>
}

export interface ModalSplitStep {
    stepId: string
    disabled?: boolean
    [key: string]: any
}

interface UseModalStepIndicator {
    activeStep: string
    activeStepIndex: number
    setActiveStep: (step: string) => void
    nextStep: () => void
    previousStep: () => void
}

export function useModalSplitSteps({
    steps = [],
    initialActiveStep = ''
}: {
    steps?: ModalSplitStep[]
    initialActiveStep?: string
}): UseModalStepIndicator {
    const getStepIndexFromId = ({steps, stepId}: {steps: ModalSplitStep[]; stepId: string}) => {
        return steps.findIndex((step) => step.stepId === stepId)
    }

    const activeIndex = getStepIndexFromId({steps, stepId: initialActiveStep})
    const [active, setActive] = React.useState(
        activeIndex >= 0 && activeIndex < steps.length ? activeIndex : 0
    )
    const setActiveStep = (stepId: string | number) => {
        if (typeof stepId === 'number') {
            setActive(stepId)
        } else {
            setActive(getStepIndexFromId({steps, stepId}))
        }
    }

    const previousStep = () => {
        if (active === 0) {
            return
        }

        setActiveStep(active - 1)
    }

    const nextStep = () => {
        if (active === steps.length - 1) {
            return
        }

        setActiveStep(active + 1)
    }

    return {
        activeStep: steps[active].stepId,
        activeStepIndex: active,
        setActiveStep,
        nextStep,
        previousStep
    }
}

interface StepIndicatorProps {
    steps: ModalSplitStep[]
    activeStep: string
    onClickStep?: (step: string) => void
}

const StepIndicator = ({steps, activeStep, onClickStep}: StepIndicatorProps) => {
    const translations = useLocalisation()

    if (steps.length < 1) {
        return null
    }
    return (
        <Dots role="tablist">
            {steps.map(({stepId, disabled}, index) => (
                <Dot
                    key={`steps-dot-${stepId}`}
                    role="tab"
                    disabled={disabled}
                    onClick={() => onClickStep?.(stepId)}
                    aria-selected={stepId === activeStep}
                >
                    <VisuallyHidden>
                        {translations.ModalSplit.StepButton} {index + 1}
                    </VisuallyHidden>
                </Dot>
            ))}
        </Dots>
    )
}

const dotDefaultSize = '8px'
const dotActiveSize = '12px'

const Dots = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: ${dotActiveSize};
    gap: ${tokens.spacing8};
    margin-bottom: ${tokens.spacing16};
    ${inlinePadding}
`

const Dot = styled(NakedButton)`
    height: ${dotDefaultSize};
    width: ${dotDefaultSize};
    border-radius: ${tokens.circle};
    background-color: ${customColorSchemeTokens.colorBackgroundInactiveIndicator};
    transition: height ${tokens.motionWithinSmallLong}, width ${tokens.motionWithinSmallLong},
        background-color 0.24s ease-out;

    &[aria-selected='true'] {
        background-color: ${customColorSchemeTokens.colorBackgroundActiveIndicator};
        height: ${dotActiveSize};
        width: ${dotActiveSize};
        cursor: default;
    }

    &:disabled {
        background-color: ${tokens.colorBackgroundInteractiveDisabled};
        cursor: not-allowed;
    }
`

const Content = styled.div`
    flex: 1 0 50%;
    max-width: 50%;
    padding-top: ${tokens.spacing40};
    padding-bottom: 0;
    max-height: inherit;
    display: flex;
    flex-direction: column;

    &:not(:has(${Dots})) {
        padding-top: 0;
        ${BodyContainer} {
            padding-top: ${tokens.spacing40};
        }
    }

    @media (max-width: ${splitBreakpoint}) {
        width: 100%;
        max-width: unset;
        flex-basis: auto;
        max-height: calc(100% - 160px);
    }
`

ModalSplit.Title = SplitTitle
ModalSplit.Body = SplitBody
ModalSplit.Actions = SplitActions
ModalSplit.BackButton = BackButton
ModalSplit.NextButton = NextButton
ModalSplit.SectionTitle = SplitSectionTitle
ModalSplit.Text = SplitText
ModalSplit.Footer = SplitFooter
ModalSplit.Content = SplitContent
ModalSplit.IllustrationContainer = IllustrationContainer
ModalSplit.Illustration = Illustration
ModalSplit.StepIndicator = StepIndicator
ModalSplit.Steps = Steps
ModalSplit.Step = Step

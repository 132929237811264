import * as RadixPopover from '@radix-ui/react-popover'
import React from 'react'

import {FilterButton} from './filter-button'

import {Popover, PopoverCloseIcon} from '../popover'

type FilterMenuApplyProps = Omit<RadixPopover.PopoverCloseProps, 'asChild'>
const FilterMenuApply = ({children, ...rest}: FilterMenuApplyProps) => (
    <RadixPopover.Close {...rest} asChild>
        <Popover.ActionButton>{children}</Popover.ActionButton>
    </RadixPopover.Close>
)

type FilterMenuClearProps = React.ButtonHTMLAttributes<HTMLButtonElement>
const FilterMenuClear = ({children, ...rest}: FilterMenuClearProps) => (
    <Popover.ActionButton {...rest}>
        <PopoverCloseIcon /> {children}
    </Popover.ActionButton>
)

type FilterProps = {
    children: React.ReactNode
    /** Method called when the menu is opened */
    onOpen?: () => void
    /** Method called when the menu is close */
    onClose?: () => void
}
export const FilterMenu = ({children, onClose, onOpen, ...props}: FilterProps) => (
    <Popover
        onOpenChange={(open) => {
            if (open && !!onOpen) {
                onOpen()
            } else if (!open && onClose) {
                onClose()
            }
        }}
        {...props}
    >
        {children}
    </Popover>
)
const FilterMenuTrigger = Popover.Trigger
const FilterMenuActions = Popover.ControlsContainer

type FilterMenuContentProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'align'> & {
    /**
     * We mainly align "end" when showing a "more filters" toggle on the right
     * @default 'start'
     */
    align?: 'start' | 'end'
}
const FilterMenuContent = ({children, align = 'start', ...rest}: FilterMenuContentProps) => (
    <Popover.Content align={align} {...rest}>
        <Popover.Arrow />
        {children}
    </Popover.Content>
)

FilterMenu.Actions = FilterMenuActions
FilterMenu.Apply = FilterMenuApply
FilterMenu.Clear = FilterMenuClear
FilterMenu.Trigger = FilterMenuTrigger
FilterMenu.Content = FilterMenuContent
FilterMenu.Toggle = FilterButton

import styled, {DataAttributes, css} from 'styled-components'

import type {LineHeightValues} from '@pleo-io/telescope-tokens/dist/line-height.types'

import {tokens, customColorSchemeTokens} from '../../tokens'

export type ColorType =
    | 'inherit'
    | (typeof textColors)[number]
    | 'colorContentInteractive'
    | 'colorContentInteractiveHover'
    | 'colorContentInteractiveQuiet'
    | 'colorContentInteractiveLink'
    | 'colorContentInteractiveLinkHover'
    | 'colorContentInteractiveDisabled'
    | 'colorContentInteractiveInverse'
    | 'colorContentInteractiveInverseHover'
    | 'colorContentInteractiveSelected'
    | 'colorContentInteractiveSelectedHover'
    | 'colorContentInteractivePlaceholder'
    | 'colorContentStatic'
    | 'colorContentStaticQuiet'
    | 'colorContentStaticInverse'
    | 'colorContentNegative'
    | 'colorContentPositive'
    | 'colorContentWarning'
    | 'colorContentInfo'
    | 'colorContentDiscover'

export interface Props {
    /**
     * Set the `text-align` property
     */
    align?: 'left' | 'right' | 'center'
    /**
     * Pass the `truncate` prop to render an ellipsis when the text exceeds the width of the parent.
     */
    truncate?: boolean
    /**
     * Render only this number of lines of text, with an ellipsis at the end of the last line if the number of lines is exceeded.
     */
    maxLines?: number
    /**
     * Add bottom margin to the text element. Use [`Stack`](/components/layout/develop/#stack) for more flexibility.
     */
    spacing?: 4 | 10 | 20 | 32
}

const truncate = ({truncate}: Props) =>
    truncate &&
    css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `

const lines = ({maxLines}: Props) =>
    maxLines &&
    css`
        display: -webkit-box;
        -webkit-line-clamp: ${maxLines};
        -webkit-box-orient: vertical;
        overflow: hidden;
    `

const align = ({align}: Props) =>
    align &&
    css`
        text-align: ${align};
    `

const italic = ({italic}: {italic?: boolean}) =>
    italic &&
    css`
        font-style: italic;
    `

const uppercase = ({uppercase}: {uppercase?: boolean}) =>
    uppercase &&
    css`
        text-transform: uppercase;
    `

const space = ({space}: {space?: 4 | 10 | 20 | 32}) =>
    space &&
    css`
        margin-bottom: ${space}px;
    `

const getFontWeight = (weight?: Weight) => {
    return weight ? Weights[weight] : tokens.fontWeightRegular
}

export type Weight = 'regular' | 'medium' | 'semibold' | 'bold'

const Weights: {[weight in Weight]: number} = {
    regular: tokens.fontWeightRegular,
    medium: tokens.fontWeightMedium,
    semibold: tokens.fontWeightSemibold,
    bold: tokens.fontWeightBold
}

const textColors = [
    'shade000',
    'shade600',
    'shade700',
    'shade800',
    'shade900',
    'red700',
    'green800',
    'inherit'
] as const

const darkTextColors = ['shade000', 'shade600', 'shade700', 'shade800', 'shade900'] as const

export interface TextProps {
    /**
     * Use the variant prop to change the visual style of the Text. Defaults to `medium-default`.
     */
    variant?: Variants
    /**
     * The tag or component to render, mandatory. Ensure that you follow semantic naming for a11y. Defaults to `div`.
     */
    as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
    /**
     * Set the `text-align` property
     */
    align?: 'left' | 'right' | 'center'
    /**
     * Pass the `truncate` prop to render an ellipsis when the text exceeds the width of the parent.
     */
    truncate?: boolean
    /**
     * Render only this number of lines of text, with an ellipsis at the end of the last line if the number of lines is exceeded. Requires `display:-webkit-box` to work, you will not be able to override the css `display` value.
     */
    maxLines?: number
    /**
     * Add bottom margin to the text element. Use [`Stack`](/components/layout/develop/#stack) for more flexibility.
     */
    space?: 4 | 10 | 20 | 32
    /**
     * Set the weight of the text.
     */
    weight?: Weight
    /**
     * Set the color of the text. Some variants are more restrictive to colors, see the [Design](/components/text/design) tab for allowed values per variant.
     */
    color?: ColorType
    /**
     * Set `text-transform: uppercase`.
     */
    uppercase?: boolean
    /**
     * Set `font-style: italic`.
     */
    italic?: boolean
}

// Note, the xsmall-subtle variant has been deprecated, see eslint-plugin-telescope
export type Variants =
    | 'xsmall-subtle'
    | 'small-subtle'
    | 'medium-default'
    | 'large-accent'
    | 'xlarge-accent'
    | '2xlarge-accent'
    | '3xlarge-accent'
    | '4xlarge-accent'
    | '5xlarge-accent'

export const variants: {
    [key in Variants]: {
        size: number
        color: string
        colors: readonly string[]
        lineHeight: LineHeightValues
        weights: readonly string[]
    }
} = {
    // Note, the xsmall-subtle variant has been deprecated, see eslint-plugin-telescope
    'xsmall-subtle': {
        size: 10,
        color: tokens.colorContentStaticQuiet,
        colors: textColors,
        lineHeight: tokens.lineHeight3,
        weights: ['bold', 'regular', 'medium', 'semibold']
    },
    'small-subtle': {
        size: 12,
        color: tokens.colorContentStaticQuiet,
        colors: textColors,
        lineHeight: tokens.lineHeight3,
        weights: ['regular', 'medium', 'semibold', 'bold']
    },
    'medium-default': {
        size: 14,
        color: tokens.colorContentStatic,
        colors: textColors,
        lineHeight: tokens.lineHeight2,
        weights: ['regular', 'medium', 'semibold', 'bold']
    },
    'large-accent': {
        size: 16,
        color: tokens.colorContentStatic,
        colors: darkTextColors,
        lineHeight: tokens.lineHeight2,
        weights: ['regular', 'medium', 'semibold', 'bold']
    },
    'xlarge-accent': {
        size: 18,
        color: tokens.colorContentStatic,
        colors: darkTextColors,
        lineHeight: tokens.lineHeight2,
        weights: ['regular', 'medium', 'semibold', 'bold']
    },
    '2xlarge-accent': {
        size: 20,
        color: tokens.colorContentStatic,
        colors: darkTextColors,
        lineHeight: tokens.lineHeight2,
        weights: ['regular', 'medium', 'semibold', 'bold']
    },
    '3xlarge-accent': {
        size: 24,
        color: tokens.colorContentStatic,
        colors: darkTextColors,
        lineHeight: tokens.lineHeight2,
        weights: ['regular', 'medium', 'semibold', 'bold']
    },
    '4xlarge-accent': {
        size: 32,
        color: tokens.colorContentStatic,
        colors: darkTextColors,
        lineHeight: tokens.lineHeight1,
        weights: ['regular', 'medium']
    },
    '5xlarge-accent': {
        size: 48,
        color: tokens.colorContentStatic,
        colors: darkTextColors,
        lineHeight: tokens.lineHeight1,
        weights: ['regular', 'medium']
    }
}

const handleThemeColor = (color: ColorType) => {
    switch (color) {
        case 'shade000':
            return customColorSchemeTokens.colorContentTextShade000
        case 'shade600':
            return customColorSchemeTokens.colorContentTextShade600
        case 'shade700':
            return customColorSchemeTokens.colorContentTextShade700
        case 'shade800':
            return customColorSchemeTokens.colorContentTextShade800
        case 'shade900':
            return customColorSchemeTokens.colorContentTextShade900
        case 'green800':
            return customColorSchemeTokens.colorContentTextGreen800
        case 'red700':
            return customColorSchemeTokens.colorContentTextRed700
        case 'inherit':
            return 'inherit'
    }
    return undefined
}

function getTextColor({color, variant = 'medium-default'}: TextProps) {
    const defaultVariantColor = variants[variant].color

    if (!color) {
        return defaultVariantColor
    }

    if (color === 'inherit') {
        return color
    }

    // if semantic token is used, use that token directly
    if (color.startsWith('colorContent')) {
        return tokens[color]
    }

    return variants[variant].colors.includes(color) ? handleThemeColor(color) : defaultVariantColor
}

const TRANSIENT_PROPS: (keyof TextProps)[] = [
    'variant',
    'align',
    'truncate',
    'maxLines',
    'space',
    'weight',
    'color',
    'uppercase',
    'italic'
]
const shouldForwardProp = (propName: string) =>
    !TRANSIENT_PROPS.includes(propName as keyof TextProps)

export const Text = styled.div
    .withConfig({shouldForwardProp})
    .attrs<TextProps & DataAttributes>((props) => ({
        as: props.as || 'div',
        'data-telescope': props['data-telescope'] ?? 'text'
    }))<TextProps>`
    margin: 0;
    ${align}
    ${truncate}
    ${lines}
    ${space}
    ${italic}
    ${uppercase}
    color: ${getTextColor};
    font-weight: ${({weight}) => getFontWeight(weight)};
    font-size: ${({variant = 'medium-default'}) => variants[variant].size}px;
    line-height: ${({variant = 'medium-default'}) => variants[variant].lineHeight};

    ${(props) =>
        ['xsmall-subtle', 'small-subtle', 'medium-default'].includes(
            props.variant ?? 'medium-default'
        )
            ? uppercase(props)
            : ''};
    i,
    em {
        font-style: italic;
    }

    b,
    strong {
        font-weight: ${tokens.fontWeightSemibold};
    }
`

Text.displayName = 'Text'

import {useEffect, useState} from 'react'

export enum Locale {
    de_DE = 'de-DE', // German (Germany)
    da_DK = 'da-DK', // Danish (Denmark)
    es_ES = 'es-ES', // Spanish (Spain)
    en_IE = 'en-IE', // English (Ireland)
    en_GB = 'en-GB', // English (United Kingdom)
    sv_SE = 'sv-SE', // Swedish (Sweden)
    fr_FR = 'fr-FR' // French (France)
}

export function useFormatCurrency(
    locale: string = Locale.da_DK,
    currency?: string,
    formatterConfig?: Intl.NumberFormatOptions
) {
    const baseFormatter = new Intl.NumberFormat(locale, {
        style: currency ? 'currency' : 'decimal',
        currency
    })

    const resolvedFormatter = new Intl.NumberFormat(locale, {
        ...baseFormatter.resolvedOptions(), // Get any currency-specific configuration,
        style: 'decimal', // Hide currency symbol by default,
        ...formatterConfig // Apply any user overrides
    })

    return (amount: number) => {
        return amount !== null ? resolvedFormatter.format(amount) : '-'
    }
}

/**
 * Debounce return of a value passed in first parameter after specified delay.
 */
export const useDebouncedValue = <T>(value: T, delay: number): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        const delayedSetDebouncedValue = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)
        return () => {
            clearTimeout(delayedSetDebouncedValue)
        }
    }, [delay, value])

    return debouncedValue
}

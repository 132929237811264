import styled from 'styled-components'

import type {AvatarSize} from './types'

import {tokens} from '../../tokens'

type Props = {$size: AvatarSize; $stacked?: boolean}

const spaceMapping = {
    24: tokens.spacing6,
    40: tokens.spacing12,
    48: tokens.spacing14,
    72: tokens.spacing24
}

const marginRight = ({$size, $stacked}: Props) => {
    const space = spaceMapping[$size]
    return $stacked ? `-${space}` : space
}

export const AvatarGroup = styled.div<{$size: AvatarSize; $stacked?: boolean}>`
    display: flex;

    > *:not(:last-child) {
        margin-right: ${marginRight};
    }
`

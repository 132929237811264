export type ThemeType = 'neutral' | 'negative' | 'positive' | 'warning' | 'discover' | 'info'

export const theme: Record<ThemeType, string> = {
    neutral: 'telescope-theme-neutral',
    info: 'telescope-theme-info',
    warning: 'telescope-theme-warning',
    negative: 'telescope-theme-negative',
    positive: 'telescope-theme-positive',
    discover: 'telescope-theme-discover'
}

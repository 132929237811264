import styled, {DataAttributes} from 'styled-components'
import {
    border,
    BorderProps,
    borderRadius,
    BorderRadiusProps,
    space,
    SpaceProps
} from 'styled-system'

import type {RadiusValues} from '@pleo-io/telescope-tokens/dist/radius.types'
import type {SpacingValues} from '@pleo-io/telescope-tokens/dist/spacing.types'

export interface BoxProps extends SpaceProps, BorderRadiusProps, BorderProps {
    /** Margin on top, left, bottom and right */
    m?: SpacingValues | 0 | 'auto'
    /** Margin on top, left, bottom and right */
    margin?: SpacingValues | 0
    /** Margin on top */
    mt?: SpacingValues | 0
    /** Margin on top */
    marginTop?: SpacingValues | 0
    /** Margin on right */
    mr?: SpacingValues | 0
    /** Margin on right */
    marginRight?: SpacingValues | 0
    /** Margin on bottom */
    mb?: SpacingValues | 0
    /** Margin on bottom */
    marginBottom?: SpacingValues | 0
    /** Margin on left */
    ml?: SpacingValues | 0
    /** Margin on left */
    marginLeft?: SpacingValues | 0
    /** Margin on left and right */
    mx?: SpacingValues | 0 | 'auto'
    /** Margin on left and right */
    marginX?: SpacingValues | 0 | 'auto'
    /** Margin on top and bottom */
    my?: SpacingValues | 0 | 'auto'
    /** Margin on top and bottom */
    marginY?: SpacingValues | 0 | 'auto'
    /** Padding on top, left, bottom and right */
    p?: SpacingValues | 0
    /** Padding on top, left, bottom and right */
    padding?: SpacingValues | 0
    /** Padding on top */
    pt?: SpacingValues | 0
    /** Padding on top */
    paddingTop?: SpacingValues | 0
    /** Padding on right */
    pr?: SpacingValues | 0
    /** Padding on right */
    paddingRight?: SpacingValues | 0
    /** Padding on bottom */
    pb?: SpacingValues | 0
    /** Padding on bottom */
    paddingBottom?: SpacingValues | 0
    /** Padding on left */
    pl?: SpacingValues | 0
    /** Padding on left */
    paddingLeft?: SpacingValues | 0
    /** Padding on left and right */
    px?: SpacingValues | 0
    /** Padding on left and right */
    paddingX?: SpacingValues | 0
    /** Padding on top and bottom */
    py?: SpacingValues | 0
    /** Padding on top and bottom */
    paddingY?: SpacingValues | 0
    /** Border Radius */
    borderRadius?: RadiusValues
    /** Border */
    border?: string
}

const TRANSIENT_PROPS = [...space.propNames!, ...border.propNames!, ...borderRadius.propNames!]
const shouldForwardProp = (prop: string) => !TRANSIENT_PROPS.includes(prop)

export const Box = styled.div.withConfig({shouldForwardProp}).attrs<BoxProps & DataAttributes>({
    'data-telescope': 'box'
})<BoxProps & DataAttributes>`
    ${space}
    ${border}
    ${borderRadius}
`

Box.displayName = 'Box'

import styled from 'styled-components'

import {tokens} from '../../tokens'

export const Count = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 24px;
    height: 20px;
    padding: 0 ${tokens.spacing8};
    color: ${tokens.colorContentStatic};
    font-size: ${tokens.fontSmall};
    font-weight: ${tokens.fontWeightRegular};
    background: ${tokens.colorBackgroundPresentationalPink};
    border-radius: ${tokens.arc99999};
    white-space: nowrap;
    user-select: none;

    [disabled] & {
        background-color: ${tokens.colorBackgroundInteractiveDisabled};
        color: ${tokens.colorContentInteractiveDisabled};
    }
`

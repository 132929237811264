import styled, {IStyledComponent} from 'styled-components'

import type {PromotionalBannerVariants, PromotionalBannerImageProps} from './promotional-banner'

import {tokens} from '../../tokens'
import {Button, ButtonProps, NakedButton} from '../button'
import {Text} from '../text'

const breakpoints = {
    medium: '700px',
    small: '500px'
}

export const BannerContent = styled.div``

export const ContentHeading = styled(Text).attrs({variant: 'xlarge-accent'})`
    margin-bottom: ${tokens.spacing4};
`
export const Image = styled.img<PromotionalBannerImageProps>`
    align-self: flex-start;
    width: 60px;
`

export const BannerCTAButton = styled(Button).attrs({
    variant: 'primary'
})`
    align-self: center;
    max-width: fit-content;

    @container (width > ${breakpoints.medium}) {
        margin-left: auto;
    }

    @container ((width > ${breakpoints.small}) and (width <= ${breakpoints.medium})) {
        ${Image} ~ & {
            grid-column-start: 2;
        }
    }
` as IStyledComponent<'web', Omit<ButtonProps, 'variant'>>
// In styled-components v6 the return type of attrs() is not inferred properly.
// Without this type cast it is still required to specity `variant` prop when
// the component is used, even though we're setting default value via attrs().
// https://github.com/styled-components/styled-components/issues/4076

export const CloseButton = styled(NakedButton)`
    position: absolute;
    top: ${tokens.spacing12};
    right: ${tokens.spacing12};
    display: flex;
    color: ${tokens.colorContentInteractive};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }
`

export const Container = styled.div`
    width: 100%;
    container-type: inline-size;
`

export const PromotionalBannerBase = styled.div<{$variant: PromotionalBannerVariants}>`
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: grid;
    grid-template-columns: auto;
    padding: ${tokens.spacing24};
    border-radius: ${tokens.arc8};
    border: 1px solid;
    border-color: ${({$variant}) => {
        if ($variant === 'bordered') {
            return tokens.colorBorderStatic
        } else {
            return `transparent`
        }
    }};
    gap: ${tokens.spacing20};
    background-color: ${({$variant}) => {
        if ($variant === 'yellow') {
            return tokens.colorBackgroundPresentationalYellow
        } else if ($variant === 'gray') {
            return tokens.colorBackgroundPresentationalGray
        } else if ($variant === 'bordered') {
            return tokens.colorBackgroundStatic
        } else if ($variant === 'purple') {
            return tokens.colorBackgroundPresentationalPurple
        }

        return tokens.colorBackgroundPresentationalPink
    }};

    &:has(${CloseButton}) {
        padding-right: ${tokens.spacing48};
    }

    @container (width > ${breakpoints.medium}) {
        display: flex;
    }
`
